const ContentType = {
  Survey: 0,
  Static: 1,
  FAQ: 2,
  Article: 3,
  Course: 4,
  Question: 5,
  Answer: 6,
  Video: 7,
  Podcast: 8,
  Document: 9
};

export default ContentType;