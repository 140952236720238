import {
  FaBookMedical,
  FaBookReader,
  FaCaretRight,
  FaCogs,
  FaFlag,
  FaHandHoldingMedical,
  FaHome,
  FaHospitalUser,
  FaTruck,
  FaUserAlt,
  FaUserCircle,
  FaUserEdit,
  FaUserMd,
  FaUserNurse,
  FaUsers,
  FaUsersCog,
  FaUserShield,
  FaUserTag,
  FaUserTie,
  FaVideo,
} from 'react-icons/fa';
import { GiJourney, GiMedicines } from 'react-icons/gi';
import { HiTemplate } from 'react-icons/hi';
import { RiAdminFill, RiHeartPulseFill, RiMailFill, RiMailSettingsFill, RiUploadCloudFill, RiUserHeartFill } from 'react-icons/ri';
import { TbEdit } from 'react-icons/tb';
import { TiFlowChildren } from 'react-icons/ti';

import { Layout, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { ImUserPlus } from 'react-icons/im';
import { MdLocationCity } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { ActionType, ActionTypes, UserRoleActionType, UserRoleType, UserRoleTypes } from '../../../constants';
import colors from '../../../styles/constants/colors';
import { Link } from '../../globals';
import { TruckIcon } from '../../icons';

const { Sider } = Layout;

export default function SidebarBase({ user, size, collapsed, setCollapsed }) {
  const { t } = useTranslation();

  const getRoleIcon = (role) => {
    switch (role) {
      case UserRoleType.Owner:
      case UserRoleType.Administrator:
      case UserRoleType.Manager:
        return <RiAdminFill />;
      case UserRoleType.Editor:
        return <FaUserEdit />;
      case UserRoleType.PSP:
        return <FaUserTie />;
      case UserRoleType.Doctor:
        return <FaUserMd />;
      case UserRoleType.Nurse:
        return <FaUserNurse />;
      case UserRoleType.Pharmacist:
        return <FaUserTag />;
      case UserRoleType.Warehouse:
      case UserRoleType.IMS:
        return <FaUserShield />;
      case UserRoleType.Patient:
        return <RiUserHeartFill />;

      default:
        return <FaUserAlt />;
    }
  };

  const getMenuItems = () => {
    let userRoleActions = ActionTypes.filter((x) => x.data?.userRoleActionType === UserRoleActionType.List && user?.checkAction(x.value)).map((x) => {
      x.userRoleData = UserRoleTypes.find((f) => f.value === x.data.userRole);
      return x;
    });

    let items = [
      {
        key: 'home',
        icon: <FaHome />,
        label: <Link to="/">{t('Home')}</Link>,
      },
    ];

    // #region [Users]
    let userMenuList = [];
    if (user?.checkAction(ActionType.UserInviteList)) {
      userMenuList.push({
        key: 'invite',
        icon: <ImUserPlus />,
        label: <Link to="/user/invite">{t('UserInvite')}</Link>,
      });
    }

    if (userRoleActions) {
      userMenuList = [
        ...userMenuList,
        ...userRoleActions.map((x, i) => ({
          key: `user-${i}`,
          icon: getRoleIcon(x.data.userRole),
          label: <Link to={`/user/list/${x.data.userRole}`}>{t(`Menu${x.userRoleData.displayName}`)}</Link>,
        })),
      ];
    }

    if (userMenuList.length > 0) {
      items = [
        ...items,
        {
          key: 'users',
          icon: <FaUsers />,
          label: t('Users'),
          children: userMenuList,
        },
      ];
    }
    // #endregion

    // #region [Delivery]
    let deliveryMenuList = [];
    if (user?.checkAction(ActionType.DeliveryList) && user?.dd) {
      deliveryMenuList.push({
        key: 'deliveries',
        icon: <TruckIcon style={{ fontSize: 30, marginRight: 10, color: colors.lavenderGray }} />,
        label: <Link to="/delivery/list">{t('Deliveries')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.DeliveryFlowList)) {
      deliveryMenuList.push({
        key: 'delivery-flows',
        icon: <TiFlowChildren />,
        label: <Link to="/delivery/flow/list">{t('DeliveryFlows')}</Link>,
      });
    }

    if (deliveryMenuList.length > 0) {
      items = [
        ...items,
        {
          key: 'delivery-management',
          icon: <FaTruck />,
          label: t('DeliveryManagement'),
          children: deliveryMenuList,
        },
      ];
    }
    // #endregion

    // #region [My Profile]
    let profileMenuList = [];

    if (user?.checkAction(ActionType.TreatmentList)) {
      profileMenuList.push({
        key: 'treatments',
        icon: <FaHandHoldingMedical />,
        label: <Link to="/treatment/list">{t('Treatments')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.JourneyPage)) {
      profileMenuList.push({
        key: 'journey',
        icon: <GiMedicines />,
        label: <Link to="/journey">{t('Journey')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.ConferenceList)) {
      profileMenuList.push({
        key: 'conferences',
        icon: <FaVideo />,
        label: <Link to="/conference/list">{t('Conferences')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.VitalList)) {
      profileMenuList.push({
        key: 'vitals',
        icon: <RiHeartPulseFill />,
        label: <Link to="/vital/list">{t('Vitals')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.VisitList)) {
      profileMenuList.push({
        key: 'visit',
        icon: <FaHospitalUser />,
        label: <Link to="/visit/list">{t('Visits')}</Link>,
      });
    }

    profileMenuList.push({
      key: 'profile',
      icon: <FaUserCircle />,
      label: <Link to="/profile">{t('Profile')}</Link>,
    });

    if (profileMenuList.length > 0) {
      items = [
        ...items,
        {
          key: 'profile-list',
          icon: <GiJourney />,
          label: t('MyProfile'),
          children: profileMenuList,
        },
      ];
    }
    // #endregion

    // #region [Content Management]
    let contentMenuList = [];

    if (user?.checkAction(ActionType.StorageList)) {
      contentMenuList.push({
        key: 'storages',
        icon: <RiUploadCloudFill />,
        label: <Link to="/storage/list">{t('Storage')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.ContentList)) {
      contentMenuList.push({
        key: 'contents',
        icon: <TbEdit />,
        label: <Link to="/content/list">{t('ContentManagement')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.ContentPage)) {
      contentMenuList.push({
        key: 'content',
        icon: <FaBookReader />,
        label: <Link to="/contents">{t('Contents')}</Link>,
      });
    }

    if (contentMenuList.length > 0) {
      items = [
        ...items,
        {
          key: 'content-menu',
          icon: <FaBookMedical />,
          label: t('ContentManagement'),
          children: contentMenuList,
        },
      ];
    }
    // #endregion

    // #region [Settings]
    let settingsMenuList = [];
    if (user?.checkAction(ActionType.RoleList)) {
      settingsMenuList.push({
        key: 'roles',
        icon: <FaUsersCog />,
        label: <Link to="/role/list">{t('Roles')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.LocalizationList)) {
      settingsMenuList.push({
        key: 'localizations',
        icon: <FaFlag />,
        label: <Link to="/localization/list">{t('Localizations')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.TemplateList)) {
      settingsMenuList.push({
        key: 'templates',
        icon: <HiTemplate />,
        label: <Link to="/template/list">{t('Templates')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.NoticeList)) {
      settingsMenuList.push({
        key: 'notices',
        icon: <RiMailFill />,
        label: <Link to="/notice/list">{t('Notices')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.NoticeContactList)) {
      settingsMenuList.push({
        key: 'noticeContacts',
        icon: <RiMailSettingsFill />,
        label: <Link to="/notice/contact/list">{t('NoticeContacts')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.CityList)) {
      settingsMenuList.push({
        key: 'cities',
        icon: <MdLocationCity />,
        label: <Link to="/city/list">{t('Cities')}</Link>,
      });
    }

    if (settingsMenuList.length > 0) {
      items = [
        ...items,
        {
          key: 'settings-menu',
          icon: <FaCogs />,
          label: t('Settings'),
          children: settingsMenuList,
        },
      ];
    }
    // #endregion

    return items;
  };

  return (
    <Aside
      collapsible={size?.width <= 1920}
      collapsed={collapsed && size?.width <= 1920}
      onCollapse={(value) => setCollapsed(value)}
      trigger={
        <SiderTrigger collapsed={collapsed && size?.width <= 1920}>
          <FaCaretRight />
        </SiderTrigger>
      }
    >
      <LogoContainer collapsed={collapsed && size?.width <= 1920}>
        <Link to="/">
          <img src="/assets/images/logo.svg" alt="Logo" />
        </Link>
      </LogoContainer>
      <SideMenu
        defaultSelectedKeys={['home']}
        defaultOpenKeys={['users', 'delivery-management', 'profile-list', 'content-menu', 'settings-menu']}
        mode="inline"
        items={getMenuItems()}
      />
    </Aside>
  );
}

const Aside = styled(Sider)`
  width: 310px !important;
  flex: 0 0 310px !important;
  max-width: 310px !important;
  min-width: 310px !important;
  transition: all ease-in-out 0.1s;

  height: 100vh;
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  background: url(/assets/images/layout/menu-bg.jpg) right bottom no-repeat;
  background-size: cover;
  background-color: #fff;
  box-shadow: 0px 0px 6px 1px ${(x) => x.theme.colors.deepKaomaru};

  .ant-layout-sider-trigger {
    height: 40px;
    width: 310px !important;
    background-color: transparent;
    color: ${(x) => x.theme.colors.white};
  }

  &.ant-layout-sider-collapsed {
    width: 60px !important;
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;

    .ant-layout-sider-trigger {
      width: 60px !important;
    }
  }
`;

const LogoContainer = styled.div`
  height: 120px;
  width: 100%;

  a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    transition: padding-left ease-in-out 0.2s;
    img {
      height: 60px;
    }
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      a {
        padding-left: 6px !important;
      }
    `}
`;

const SideMenu = styled(Menu)`
  overflow-x: hidden;
  height: 100%;
  max-height: calc(100% - 112px);
  border-right: 0px;
  color: ${(x) => x.theme.colors.lavenderGray};
  background: transparent;
  direction: rtl;
  ::-webkit-scrollbar {
    width: 0px;
  }

  .ant-menu-item {
    padding: 32px 0px;
    margin: 0px !important;
    direction: ltr;
    a {
      color: ${(x) => x.theme.colors.lavenderGray};
      &:hover {
        text-decoration: none;
      }
    }

    svg {
      color: ${(x) => x.theme.colors.lavenderGray};
      font-size: 30px;
    }

    &.ant-menu-item-selected {
      background-color: transparent;

      svg {
        color: ${(x) => x.theme.colors.darkTurquoise} !important;
      }
      a {
        color: ${(x) => x.theme.colors.darkTurquoise};
      }
      &::after {
        display: none;
      }
    }

    &:active,
    .ant-menu-submenu-title:active {
      background: transparent;
    }

    &:hover {
      color: ${(x) => x.theme.colors.darkTurquoise};

      svg {
        color: ${(x) => x.theme.colors.darkTurquoise} !important;
      }

      a {
        color: ${(x) => x.theme.colors.darkTurquoise};
      }
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-arrow,
    .ant-menu-submenu-expand-icon {
      color: ${(x) => x.theme.colors.lavenderGray};
    }

    &:hover {
      > .ant-menu-submenu-title {
        color: ${(x) => x.theme.colors.darkTurquoise};

        > .ant-menu-submenu-arrow {
          color: ${(x) => x.theme.colors.darkTurquoise};
        }
      }
    }
    .ant-menu-submenu-title {
      position: relative;
      padding-left: 54px !important;
      padding: 32px 0px;
      margin: 0px;
      font-weight: bold;

      &:active {
        background: transparent;
      }

      svg {
        position: absolute;
        left: 24px;
        font-size: 30px;
      }
    }

    .ant-menu-sub.ant-menu-inline {
      background: transparent;
    }
  }

  .ant-menu-submenu-selected {
    color: ${(x) => x.theme.colors.darkTurquoise};
    > .ant-menu-submenu-title {
      color: ${(x) => x.theme.colors.darkTurquoise};

      > .ant-menu-submenu-arrow {
        color: ${(x) => x.theme.colors.darkTurquoise};
      }
    }
  }
`;

const SiderTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    font-size: 30px;
  }

  ${({ collapsed }) =>
    !collapsed &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;
