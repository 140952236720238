import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import AgoraRTC, { AgoraVideoPlayer, createClient, createMicrophoneAndCameraTracks } from 'agora-rtc-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash } from 'react-icons/fa';
import { ImPhoneHangUp } from 'react-icons/im';
import { MdScreenShare, MdStopScreenShare } from 'react-icons/md';
import { RiSettingsFill } from 'react-icons/ri';
import styled, { css } from 'styled-components';
import { ActionType } from '../../../constants';
import { conferenceLeave } from '../../../services/api';
import colors from '../../../styles/constants/colors';
import { notifyError } from '../../../utilies/notification';
import { ReduceContext } from '../../contexts/ReduceContext';
import { Button, Select } from '../../globals';

export default function ConferencePlayer({ user, conference, setConference }) {
  // Definitions
  const { t } = useTranslation();
  const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const useClient = createClient({ mode: 'rtc', codec: 'vp8' });
  const client = useClient();

  const [state, dispatch] = useContext(ReduceContext);

  const [publishes, setPublishes] = useState([]);
  const [userPublishes, setUserPublishes] = useState([]);
  const [userUnPublishes, setUserUnPublishes] = useState([]);
  const [screenSharePublish, setScreenSharePublish] = useState(null);

  const [trackState, setTrackState] = useState({ video: true, audio: true, screen: false });
  const [device, setDevice] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isDevicePanelVisibility, setIsDevicePanelVisibility] = useState(false);

  // Hooks
  useEffect(() => {
    if (ready) {
      load();
    }
  }, [ready, conference.channel, conference.token]);

  useEffect(() => {
    if (state?.conference?.leave) {
      dispatch({ ...state, conference: { leave: false } });
    }
  }, [state?.conference?.leave]);

  useEffect(() => {
    let distinctIds = [...new Set(userPublishes.map((x) => x.uid))];
    setPublishes(distinctIds.map((x) => userPublishes.find((f) => f.uid === x)).filter((x) => !!x.uid));
  }, [userPublishes]);

  useEffect(() => {
    let distinctIds = [...new Set(userUnPublishes.map((x) => x.uid))];

    setUserPublishes(userPublishes.filter((x) => !distinctIds.some((s) => s === x.uid)));
  }, [userUnPublishes]);

  // Functions
  const load = async () => {
    events();

    await login();
  };

  const events = () => {
    client.on('user-published', async (remoteUser, type) => {
      await client.subscribe(remoteUser, type);

      if (type === 'audio' && remoteUser.audioTrack) {
        remoteUser.audioTrack.play();
      }

      if (type === 'video') {
        if (!userPublishes.some((x) => x.uid === remoteUser.uid)) {
          setUserPublishes((x) => [...x, remoteUser]);
        }
      }
    });

    client.on('user-unpublished', (remoteUser) => {
      setUserUnPublishes((x) => [...x, remoteUser]);
    });

    client.on('user-left', (remoteUser) => {
      setUserUnPublishes((x) => [...x, remoteUser]);
    });
  };

  const login = async () => {
    let clientDevices = await AgoraRTC.getDevices();

    let audioInputs = clientDevices.filter((x) => x.kind === 'audioinput');
    let videoInputs = clientDevices.filter((x) => x.kind === 'videoinput');

    setDevice({ audioInputs, videoInputs });

    await client.join(conference.applicationId, conference.channel, conference.token);

    if (tracks) {
      let audioDevice = audioInputs[0].deviceId;
      let videoDevice = videoInputs[0].deviceId;

      tracks[0].setDevice(audioDevice);
      tracks[1].setDevice(videoDevice);

      setSelectedDevice({ audioDevice, videoDevice });
      await client.publish([tracks[0], tracks[1]]);
    }
  };

  const toggleScreenShare = async () => {
    try {
      if (screenSharePublish) {
        let videoTrack = screenSharePublish.localTracks;
        if (videoTrack.length > 0) {
          videoTrack[0].stop();
          videoTrack[0].close();
        }
        await screenSharePublish.leave();
        setScreenSharePublish(null);
      } else {
        let agoraScreenShare = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
        await agoraScreenShare.join(conference.applicationId, conference.channel, conference.token);

        let screenTrack = await AgoraRTC.createScreenVideoTrack({
          encoderConfig: {
            height: 1080,
            width: 1920,
          },
        });
        await agoraScreenShare.publish(screenTrack);
        setScreenSharePublish(agoraScreenShare);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mute = async (type) => {
    if (type === 'screen') {
      await toggleScreenShare();
      setTrackState((ps) => ({ ...ps, screen: !ps.screen }));
      return;
    }

    if (tracks === null) return;

    if (type === 'audio') {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => ({ ...ps, audio: !ps.audio }));
    } else if (type === 'video') {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => ({ ...ps, video: !ps.video }));
    }
  };

  const leave = async () => {
    await conferenceLeave({}, async (status, res) => {
      await client.leave();

      setTrackState(null);
      setConference(null);
      dispatch({ ...state, conference: null });

      if (window) window.location.reload();

      if (tracks) {
        tracks[0].close();
        tracks[1].close();
      }
    });
  };

  const onDeviceChange = async (type, value) => {
    if (tracks === null) return;

    switch (type) {
      case 'audio':
        tracks[0].setDevice(value);

        setSelectedDevice((x) => ({ ...x, audioDevice: value }));
        break;

      case 'video':
        tracks[1].setDevice(value);
        setSelectedDevice((x) => ({ ...x, videoDevice: value }));
        break;

      default:
        notifyError(t('NotImplemented'));
        break;
    }
  };

  // Render
  return (
    <Container>
      <Panel>
        <User>{ready && tracks && selectedDevice && <AgoraVideoPlayer videoTrack={tracks[1]} style={{ width: '100%', height: '100%' }} />}</User>

        <TargetScreen>
          {publishes?.length > 0 ? (
            publishes.map((x, i) =>
              x.videoTrack ? (
                <TargetUser key={`target-user-${i}`} id={x.uid.toString()} row={i.toString()}>
                  <AgoraVideoPlayer videoTrack={x.videoTrack} style={{ width: '100%', height: '100%' }} />
                </TargetUser>
              ) : (
                <TargetUser key={`target-user-${i}`} id={x.uid.toString()} row={i.toString()}>
                  <UserOutlined style={{ color: colors.white }} />
                </TargetUser>
              )
            )
          ) : (
            <LoadingOutlined style={{ color: colors.white }} />
          )}
        </TargetScreen>

        <Controls>
          <Button onClick={() => setIsDevicePanelVisibility(!isDevicePanelVisibility)} templates={['conference', isDevicePanelVisibility && 'close']}>
            <RiSettingsFill />
          </Button>
          <Button onClick={() => mute('audio')} templates={['conference', trackState.audio ? '' : 'close']}>
            {trackState.audio ? <FaMicrophoneSlash /> : <FaMicrophone />}
          </Button>
          <Button onClick={() => mute('video')} templates={['conference', trackState.video ? '' : 'close']}>
            {trackState.video ? <FaVideoSlash /> : <FaVideo />}
          </Button>
          {user.checkAction(ActionType.ConferenceShareScreen) && (
            <Button onClick={() => mute('screen')} templates={['conference', trackState.screen ? '' : 'close']}>
              {trackState.screen ? <MdStopScreenShare /> : <MdScreenShare />}
            </Button>
          )}
          <Button onClick={leave} templates={['conference', 'leave']}>
            {<ImPhoneHangUp />}
          </Button>
        </Controls>

        {device && isDevicePanelVisibility && (
          <DevicePanel>
            <Device key="audio-in-devices">
              <FaMicrophone />
              <Select
                data={device?.audioInputs?.map((x) => ({ value: x.deviceId, text: x.label }))}
                showSearch={true}
                allowClear={false}
                templates={['device']}
                value={selectedDevice?.audioDevice}
                onChange={(value) => onDeviceChange('audio', value)}
              />
            </Device>
            <Device key="video-in-devices">
              <FaVideo />
              <Select
                data={device?.videoInputs?.map((x) => ({ value: x.deviceId, text: x.label }))}
                showSearch={true}
                allowClear={false}
                templates={['device']}
                value={selectedDevice?.videoDevice}
                onChange={(value) => onDeviceChange('video', value)}
              />
            </Device>
          </DevicePanel>
        )}
      </Panel>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  overflow: hidden;
  z-index: 9999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Panel = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px;
`;

const User = styled.div`
  height: 400px;
  width: 400px;
  position: absolute;
  z-index: 99;
  right: 40px;
  bottom: 40px;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${(x) => x.theme.colors.lavenderGray};
`;

const TargetScreen = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${(x) => x.theme.colors.deepKaomaru};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  position: relative;
`;

const TargetUser = styled.div`
  position: absolute;
  z-index: 99;
  left: 40px;
  bottom: 40px;

  height: 200px;
  width: 200px;
  float: left;
  background-color: ${(x) => x.theme.colors.lavenderGray};
  border-radius: 20px;
  overflow: hidden;

  ${({ row }) => {
    let result = parseInt(row) * 200 + 40;
    if (parseInt(row) > 0) result += 20;

    return css`
      left: ${result}px;
    `;
  }}

  &:last-child {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

const Controls = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 9;
  padding: 10px;
  border-radius: 50px;
  background-color: ${(x) => x.theme.colors.deepKaomaru};

  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const DevicePanel = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  background-color: ${(x) => x.theme.colors.deepKaomaru};
  padding: 10px;
  border-radius: 10px;
`;

const Device = styled.div`
  height: 50px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  > svg {
    position: absolute;
    color: #000;
    z-index: 11;
    top: 15px;
    line-height: 0;
    left: 10px;
    width: 20px;
    height: 20px;
  }
`;
