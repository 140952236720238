const CountryType = {
  Global: 0,
  Russian: 1,
  India: 2,
  Chile: 3,
  Serbia: 4,
  Taiwan: 5,
  Bangladesh: 6,
  Kazakhstan: 7
};

export default CountryType;