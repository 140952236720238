import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa';
import { Button } from '../../components/globals';
import { ContentType, ContentTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function ContentUserColumns({ onSeeDetailClick }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'ContentName',
      name: 'parentContent.title',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={row.parentContent.title}>
          {row.parentContent.title}
        </Tooltip>
      ),
    },
    {
      locale: 'ContentType',
      name: 'type',
      type: 'string',
      sortable: true,
      render: (value, row) => {
        let type = ContentTypes.find((x) => x.value === row.parentContent.type);

        return type ? (
          <Tooltip placement="topLeft" title={t(type.displayName)}>
            {t(type.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'TotalPoint',
      name: 'totalPoint',
      type: 'number',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Period',
      name: 'period',
      type: 'number',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value} style={{ padding: '0px 10px' }}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onSeeDetailClick && {
      locale: 'Preview',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (v, row) =>
        row.parentContent.type === ContentType.Survey && (
          <Button key="preview" onClick={() => onSeeDetailClick(row)} templates={['table']}>
            <FaEye />
          </Button>
        ),
    },
  ];

  return ColumnBase({ columns });
}
