import { Drawer } from 'antd';
import styled, { css } from 'styled-components';

export default function DrawerBase({ children, width, title, visible, onClose, destroyOnClose, forceRender, placement, templates }) {
  return (
    <Container
      placement={placement ?? 'right'}
      visible={visible}
      onClose={onClose}
      width={width ?? 400}
      title={title}
      forceRender={forceRender ?? true}
      destroyOnClose={destroyOnClose ?? true}
      templates={templates}
    >
      {children}
    </Container>
  );
}

const Container = styled(Drawer)`
  z-index: 9999;

  .ant-drawer-content-wrapper {
    overflow: hidden;
    border-top-left-radius: 30px;
    .ant-drawer-content {
      background: ${(x) => x.theme.colors.white};

      .ant-drawer-title {
        color: ${(x) => x.theme.colors.deepKaomaru};
        font-weight: bold;
      }
    }

    @media only screen and (max-width: 400px) {
      width: 100% !important;
    }
  }

  ${({ templates }) =>
    templates?.includes('storage') &&
    css`
      z-index: 99999;

      .ant-drawer-content-wrapper {
        border-top-left-radius: 0px;
      }
    `}

  ${({ templates }) =>
    templates?.includes('upload') &&
    css`
      z-index: 99999;

      .ant-drawer-content-wrapper {
        border-top-left-radius: 0px;
      }
    `}

  ${({ templates }) =>
    templates?.includes('notification') &&
    css`
      z-index: 99999;

      .ant-drawer-content-wrapper {
        border-top-left-radius: 0px;
        border-top-right-radius: 30px;
        .ant-drawer-header {
          .ant-drawer-header-title {
            flex-direction: row-reverse;
          }
        }
      }
    `}
`;
