import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaEraser, FaLock, FaUnlockAlt } from 'react-icons/fa';
import { GiMedicines } from 'react-icons/gi';
import { HiUsers } from 'react-icons/hi';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button, PopupConfirm } from '../../components/globals';
import { CountryTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function UserColumns({ isAllCountry, onUpdateClick, onRelationClick, onStatusChangeClick, onJourneyPageClick, onAnonymizeClick }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'Reference',
      name: 'reference',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    isAllCountry && {
      locale: 'Country',
      name: 'country',
      type: 'string',
      render: (value, row) => {
        let country = CountryTypes.find((x) => x.value === value);

        return country ? (
          <Tooltip placement="topLeft" title={t(country.displayName)}>
            {t(country.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'FullName',
      name: 'fullName',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
    onRelationClick && {
      locale: 'Relation',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onRelationClick(row)} templates={['table']}>
          <HiUsers />
        </Button>
      ),
    },
    onJourneyPageClick && {
      locale: 'Journey',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onJourneyPageClick(row)} templates={['table']}>
          <GiMedicines />
        </Button>
      ),
    },
    onStatusChangeClick && {
      locale: 'Activity',
      align: 'center',
      width: 120,
      render: (v, row) =>
        !row.isActive ? (
          <PopupConfirm
            key="update-confirm"
            title={t('DoYouWantToActiveThisUser')}
            onConfirm={() => onStatusChangeClick(row)}
            okText={t('Confirm')}
            cancelText={t('Cancel')}
            placement="bottomRight"
          >
            <Button templates={['table']}>
              <FaUnlockAlt />
            </Button>
          </PopupConfirm>
        ) : (
          <Button key="update" onClick={() => onStatusChangeClick(row)} templates={['table']}>
            <FaLock />
          </Button>
        ),
    },
    onAnonymizeClick && {
      locale: 'Anonymize',
      align: 'center',
      width: 120,
      render: (v, row) =>
        row.firstName !== '******' && (
          <PopupConfirm
            key="anonymize-question"
            title={t('DoYouWantToAnonymizeThisUser')}
            onConfirm={() => onAnonymizeClick(row)}
            okText={t('Confirm')}
            cancelText={t('Cancel')}
            placement="bottomRight"
          >
            <Button templates={['table']}>
              <FaEraser />
            </Button>
          </PopupConfirm>
        ),
    },
  ];

  return ColumnBase({ columns });
}
