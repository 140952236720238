const TemplateGroupTypes = [
  {
    value: 0,
    name: 'SmsTemplate',
    displayName: 'TemplateGroupSms',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'EmailTemplate',
    displayName: 'TemplateGroupEmail',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'ContentTemplate',
    displayName: 'TemplateGroupContent',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  }
];

export default TemplateGroupTypes;