import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function DeliveryInputs({ country, drug, calculate, deliveryFlowId, targetUserId, targetUserAddress }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'country',
      initialValue: country,
    },
    {
      type: 'hidden',
      name: 'drug',
      initialValue: drug,
    },
    {
      type: 'hidden',
      name: 'calculate',
      initialValue: calculate,
    },
    {
      type: 'hidden',
      name: 'deliveryFlowId',
      initialValue: deliveryFlowId,
    },
    {
      type: 'hidden',
      name: 'targetUserId',
      initialValue: targetUserId,
    },
    {
      type: 'hidden',
      name: 'treatmentDrugCalculate',
    },
    {
      type: 'input',
      name: 'targetUserAddress',
      label: t('Address'),
      placeholder: t('AddressEmpty'),
      rules: [
        { required: true, message: t('AddressEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
      ],
      initialValue: targetUserAddress,
    },
    {
      type: 'input',
      name: 'note',
      label: t('Note'),
      placeholder: t('NoteOptional'),
      rules: [{ min: 3, max: 500, message: format(t('LengthValid'), 3, 500) }],
    },
  ];
}
