import { CheckOutlined, CloseOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Tooltip } from 'antd';
import { BsImageFill } from 'react-icons/bs';
import { FaEye, FaPlus, FaTrash } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { Button, Checkbox, DatePicker, Editor, FileInput, Input, PhoneInput, PopupConfirm, Radio, Select, Switch, Tree } from '../../globals';

export default function ElementBase({
  style,
  children,
  ready,
  submit,
  inputs,
  formRef,
  onFinish,
  layout,
  size,
  columnSize,
  requiredMark,
  scrollToFirstError,
  onValuesChange,
  disabled,
}) {
  const getFormPreviewImage = (input) => {
    if (!input?.storage) {
      return null;
    }

    let value = null;

    if (formRef) {
      let data = formRef.getFieldsValue();
      value = data[input.name];

      if (value === undefined && data[input.prefix] !== undefined) {
        value = data[input.prefix][input.name];
      }
    }

    return (
      input?.onChangeClick && (
        <StorageContainer>
          <StorageButton>
            <BsImageFill onClick={input.onChangeClick} />
          </StorageButton>

          {value && input?.imagePreview && (
            <Tooltip placement="bottomRight" title={<PreviewImage src={value} />}>
              <StorageButton>
                <FaEye />
              </StorageButton>
            </Tooltip>
          )}

          {value && input.onDeleteClick && (
            <PopupConfirm
              title={input.deletePopupTitle}
              onConfirm={input.onDeleteClick}
              okText={input.deleteConfirmTitle}
              cancelText={input.deleteCancelTitle}
              templates={['clickable']}
              placement="bottomRight"
            >
              <StorageButton>
                <FaTrash />
              </StorageButton>
            </PopupConfirm>
          )}
        </StorageContainer>
      )
    );
  };

  const getFormElement = (input, listIndex, itemIndex) => {
    switch (input.type) {
      case 'component':
        return input.content;

      case 'phone':
        return (
          <PhoneInput
            country={input.country}
            value={formRef.getFieldValue(input.name)}
            placeholder={input.placeholder}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })}
            disabled={input.disabled ?? false}
            templates={input.templates}
          />
        );

      case 'date':
        return (
          <DatePicker
            placeholder={input.placeholder}
            format={input.format ?? (input.showTime ? 'll HH:mm' : 'll')}
            style={{ width: '100%' }}
            disabledDate={input.disabledDate}
            disabledTime={input.disabledTime}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })}
            disabled={input.disabled ?? false}
            inputReadOnly={input.readOnly ?? false}
            showTime={input.showTime ?? false}
            allowClear={input.allowClear ?? true}
          />
        );

      case 'input':
        return (
          <Input
            style={input.style}
            allowClear={input.allowClear ?? true}
            placeholder={input.placeholder}
            onChange={(event) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value: event?.currentTarget?.value })}
            disabled={input.disabled ?? false}
            readOnly={input.readOnly ?? false}
            templates={input.templates}
          />
        );

      case 'number':
        return (
          <Input.Number
            placeholder={input.placeholder}
            onChange={(value) => input.onChange && input.onChange({ listIndex, itemIndex, value })}
            min={input.numberRule?.min}
            max={input.numberRule?.max}
            step={input.step}
            disabled={input.disabled ?? false}
            readOnly={input.readOnly ?? false}
            maxLength={input.maxLength}
            templates={input.templates}
          />
        );

      case 'input-password':
        return (
          <Input.Password
            style={input.style}
            allowClear={input.allowClear ?? true}
            placeholder={input.placeholder}
            onChange={(event) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value: event?.target?.value })}
            disabled={input.disabled ?? false}
            readOnly={input.readOnly ?? false}
            templates={input.templates}
          />
        );

      case 'textarea':
        return (
          <Input.TextArea
            rows={5}
            style={input.style}
            allowClear={input.allowClear ?? true}
            placeholder={input.placeholder}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })}
            disabled={input.disabled ?? false}
            readOnly={input.readOnly ?? false}
            templates={input.templates}
          />
        );

      case 'complete':
        return (
          <Input.AutoComplete
            options={input.options}
            style={input.style}
            allowClear={input.allowClear ?? true}
            placeholder={input.placeholder}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })}
            disabled={input.disabled ?? false}
            templates={input.templates}
          />
        );

      case 'hidden':
        return <Input onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })} />;

      case 'editor':
        return <Editor onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })} height={input.height} />;

      case 'select':
        return (
          <Select
            mode={input.mode}
            groups={input.groups}
            data={input.data}
            placeholder={input.placeholder}
            showSearch={input.showSearch ?? true}
            allowClear={input.allowClear ?? true}
            disabled={input.disabled ?? false}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })}
            listHeight={input.listHeight}
            templates={input.templates}
          />
        );

      case 'checkbox-group':
        return (
          <Checkbox.Group
            style={input.style}
            disabled={input.disabled}
            templates={input.templates}
            options={input.data}
            onChange={(values) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, values })}
          />
        );

      case 'checkbox':
        return (
          <Checkbox
            style={input.style}
            disabled={input.disabled}
            templates={input.templates}
            defaultChecked={input.initialValue ?? false}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })}
          />
        );

      case 'radio-group':
        return (
          <Radio.Group
            options={input.data}
            optionType={input.optionType}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value: value.target.value })}
          />
        );

      case 'switch':
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={input.initialValue ?? false}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })}
          />
        );

      case 'tree-select':
        return (
          <Tree.Select
            style={{ width: '100%' }}
            treeData={input.data}
            dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
            placeholder={input.placeholder}
            showSearch={input.showSearch ?? true}
            allowClear={input.allowClear ?? true}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })}
            treeDefaultExpandAll={true}
          />
        );

      case 'upload':
        return <FileInput files={input.files} setFiles={input.setFiles} multiple={input.multiple} />;

      default:
        return (
          <Input
            placeholder={input.placeholder}
            onChange={(value) => input.onChange && input.onChange({ listIndex: input.listIndex, itemIndex: input.itemIndex, value })}
          />
        );
    }
  };

  const getFormItem = (input, i) => {
    if (input.elements !== undefined) {
      return (
        <FormElementItems key={`key-${i}`} templates={input.templates}>
          {input.elements
            .filter((x) => x)
            .map((item, listIndex) => {
              item.listIndex = listIndex;
              return (
                <FormItemCol
                  key={`col-${i}-${listIndex}`}
                  span={item?.columnSize ?? columnSize ?? 8}
                  style={{ display: item.type === 'hidden' || item.visible === false ? 'none' : '' }}
                  templates={item.templates}
                >
                  <FormItem
                    name={[input.name, item.name]}
                    label={item.label}
                    rules={item.rules}
                    initialValue={item.initialValue}
                    css={item.css}
                    templates={item.templates}
                    valuePropName={item.type === 'switch' || item.type === 'checkbox' ? 'checked' : 'value'}
                  >
                    {getFormElement(item)}
                  </FormItem>
                </FormItemCol>
              );
            })}
        </FormElementItems>
      );
    }

    if (input.list !== undefined) {
      return (
        <Form.List key={`form-list-${i}`} name={input.name} initialValue={[0]}>
          {(formList, { add, remove }) => (
            <FormList templates={input.templates}>
              {formList.map(({ key, name, ...restField }, listIndex) => (
                <FormListItems key={key} templates={input.templates}>
                  {input.list.map((item, itemIndex) => {
                    item.listIndex = listIndex;
                    item.itemIndex = itemIndex;

                    return (
                      <FormItem
                        {...restField}
                        key={`form-item-${itemIndex}`}
                        name={[name, item.name]}
                        label={item.label}
                        rules={item.rules}
                        initialValue={item.initialValue}
                        css={item.css}
                        templates={item.templates}
                        valuePropName={item.type === 'switch' || item.type === 'checkbox' ? 'checked' : 'value'}
                      >
                        {getFormElement(item, listIndex, itemIndex)}
                      </FormItem>
                    );
                  })}

                  {input.remove && <FormListRemoveIcon onClick={() => remove(name)} />}
                </FormListItems>
              ))}

              {input.add && (
                <FormListAddIcon onClick={() => add()}>
                  <FaPlus /> {input.add}
                </FormListAddIcon>
              )}
            </FormList>
          )}
        </Form.List>
      );
    }

    return (
      <FormItemCol
        key={`col-${i}`}
        span={input?.columnSize ?? columnSize ?? 8}
        style={{ display: input.type === 'hidden' || input.visible === false ? 'none' : '' }}
        templates={input.templates}
      >
        {input.type === 'component' ? (
          input.content
        ) : (
          <FormItem
            key={`item-${i}`}
            name={input.name}
            label={input.label}
            rules={input.rules}
            initialValue={input.initialValue}
            css={input.css}
            templates={input.templates}
            valuePropName={input.type === 'switch' || input.type === 'checkbox' ? 'checked' : 'value'}
          >
            {getFormElement(input)}
          </FormItem>
        )}

        {getFormPreviewImage(input)}
      </FormItemCol>
    );
  };

  return (
    <Form
      form={formRef}
      onFinish={onFinish}
      layout={layout ?? 'vertical'}
      size={size}
      requiredMark={requiredMark ?? true}
      scrollToFirstError={scrollToFirstError ?? true}
      onValuesChange={onValuesChange}
      style={style}
    >
      <Row gutter={16}>{inputs && inputs.filter((x) => x).map((input, i) => getFormItem(input, i))}</Row>

      {children}

      {submit && (
        <Button htmlType="submit" ready={ready} templates={['colored']} block={true} disabled={disabled}>
          {submit}
        </Button>
      )}
    </Form>
  );
}

const ReadOnlyFormItem = css`
  ${({ templates }) =>
    templates?.includes('readOnly') &&
    css`
      pointer-events: none;
    `}
`;

const FilterSelectFormItem = css`
  ${({ templates }) =>
    templates?.includes('filter-select') &&
    css`
      padding-bottom: 0px;
      margin-bottom: 0px;
      min-width: 100px;
      max-width: 200px;
    `}
`;

const HorizontalFormItem = css`
  ${({ templates }) =>
    templates?.includes('horizontal-item') &&
    css`
      .ant-form-item-row {
        display: flex;
        align-items: center;
        flex-flow: row-reverse;
        .ant-col {
          &:first-child {
            width: 100%;
            padding: 0px;
            margin-left: 8px;
          }

          &:last-child {
            width: auto;
          }
        }
      }
    `}
`;

const RowNumberFormItem = css`
  ${({ templates }) =>
    templates?.includes('row-number') &&
    css`
      position: absolute;
      left: 0px;
      width: 64px;
      .ant-form-item-row {
        flex-direction: column-reverse;

        input {
          box-shadow: none !important;
          pointer-events: none;
        }
      }
    `}

  ${({ templates }) =>
    templates?.includes('treatment-row-number') &&
    css`
      .ant-form-item-row {
        input {
          font-size: 24px;
          padding: 0px;
          text-align: center;
        }
      }
    `}
`;

const FormItem = styled(Form.Item)`
  padding-bottom: 12px;
  .ant-form-item-label {
    font-weight: bold;

    color: ${(x) => x.theme.colors.deepKaomaru};
    > label {
      width: 100%;
      color: ${(x) => x.theme.colors.deepKaomaru};
    }
  }

  ${({ css }) => css}

  ${ReadOnlyFormItem}
  ${FilterSelectFormItem}
  ${HorizontalFormItem}
  ${RowNumberFormItem}
`;

const FormList = styled(Col)`
  display: block;
  flex: 0 0 100%;
  max-width: 100%;

  ${({ templates }) =>
    templates?.includes('empty-bottom') &&
    css`
      margin-bottom: 32px;
    `}
`;

const FormElementItems = styled.div`
  ${({ templates }) =>
    templates?.includes('contents') &&
    css`
      display: contents;
    `}

  ${({ templates }) =>
    templates?.includes('full-width') &&
    css`
      width: 100%;
    `}
`;

const FormListItems = styled.div`
  position: relative;

  .ant-form-item {
    padding-bottom: 12px;
    .ant-form-item-label {
      font-weight: bold;

      > label {
        color: ${(x) => x.theme.colors.deepKaomaru};
      }
    }
  }

  ${({ templates }) =>
    templates?.includes('left-number') &&
    css`
      padding-left: 48px;
    `}

  ${({ templates }) =>
    templates?.includes('contents') &&
    css`
      display: contents;
    `}

  ${({ templates }) =>
    templates?.includes('multiple') &&
    css`
      padding-right: 48px;
    `}

  ${({ templates }) =>
    templates?.includes('item-row') &&
    css`
      display: flex;
      flex-flow: row wrap;
      min-width: 0;
      justify-content: space-evenly;

      .ant-form-item {
        padding-bottom: 0px;
        margin-bottom: 8px;
      }
    `}

    ${({ templates }) =>
    templates?.includes('treatment-list') &&
    css`
      border: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
      margin-bottom: 12px;
      border-radius: ${(x) => x.theme.global.borderRadius};
      padding: 8px 52px 8px 60px;

      .anticon-minus-circle {
        right: 12px;
        top: 26px;
      }

      > div:first-child {
        left: 8px;
        width: 52px;
        top: 26px;
        margin-bottom: 0px;
      }
    `}
`;

const FormListRemoveIcon = styled(MinusCircleOutlined)`
  position: absolute;
  top: 30px;
  right: 0px;
  font-size: 17px;
  width: 32px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  color: ${(x) => x.theme.colors.deepKaomaru};
  border: 1px dashed ${(x) => x.theme.colors.deepKaomaru};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover,
  &:focus,
  &:hover,
  &:active {
    color: ${(x) => x.theme.colors.white};
    border-color: ${(x) => x.theme.colors.deepKaomaru};
    background-color: ${(x) => x.theme.colors.deepKaomaru};
    box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  }
`;

const FormListAddIcon = styled.span`
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  display: block;
  border-radius: 30px;
  width: 100%;

  color: ${(x) => x.theme.colors.deepKaomaru};
  border: 1px dashed ${(x) => x.theme.colors.deepKaomaru};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover,
  &:focus,
  &:hover,
  &:active {
    color: ${(x) => x.theme.colors.white};
    border-color: ${(x) => x.theme.colors.deepKaomaru};
    background-color: ${(x) => x.theme.colors.deepKaomaru};
    box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  }
`;

const FormItemCol = styled(Col)`
  ${({ css }) => css}
`;

const StorageContainer = styled.div`
  position: absolute;
  font-size: 18px;
  top: -4px;
  right: 10px;
  display: flex;
  color: ${(x) => x.theme.colors.robinEggBlue};
  z-index: 9;

  .anticon {
    cursor: pointer;
    color: ${(x) => x.theme.colors.robinEggBlue};
    background-color: transparent;
    padding: 0px 10px;
  }
`;

const PreviewImage = styled.img`
  width: 100%;
`;

const StorageButton = styled.div`
  height: 30px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
