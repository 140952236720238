import { Select } from 'antd';
import styled, { css } from 'styled-components';

const { Option, OptGroup } = Select;

export default function SelectBase({
  value,
  defaultValue,
  groups,
  data,
  mode,
  placeholder,
  showSearch,
  onChange,
  disabled,
  filterOption,
  allowClear,
  listHeight,
  templates,
  ...restProps
}) {
  return (
    <Container
      mode={mode}
      placeholder={placeholder}
      showSearch={showSearch !== undefined ? showSearch : true}
      allowClear={allowClear !== undefined ? allowClear : true}
      filterOption={(x, o) => o.children?.toString()?.toLocaleLowerCase()?.indexOf(x?.toString()?.toLocaleLowerCase()) >= 0 || o.value === x}
      disabled={disabled ?? false}
      onChange={onChange}
      listHeight={listHeight}
      templates={templates}
      value={value}
      defaultValue={defaultValue}
      {...restProps}
    >
      {groups
        ? groups?.map((g, gi) => (
            <OptGroup key={`group-${gi}`} label={g.key}>
              {g?.values?.map((x, vi) => (
                <Option key={`option-${gi}-${vi}`} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </OptGroup>
          ))
        : data?.map((x, i) => (
            <Option key={`option-${i}`} value={x.value}>
              {x.text}
            </Option>
          ))}
    </Container>
  );
}

const Container = styled(Select)`
  height: 50px;
  overflow: hidden;
  border-radius: ${(x) => x.theme.global.borderRadius};

  border: 0px;
  border-color: transparent !important;
  box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;

  .ant-select-selector {
    height: 50px !important;
    border: 0px !important;

    .ant-select-selection-search,
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  &:not(.ant-select-disabled) {
    .ant-select-arrow {
      background: ${(x) => x.theme.colors.white};
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      .ant-select-selection-overflow {
        overflow: hidden;
        flex-wrap: nowrap;
        height: 100%;
        .ant-select-selection-item {
          background: transparent;
          height: 30px;
          border-radius: ${(x) => x.theme.global.borderRadius};

          .ant-select-selection-item-remove {
            padding-top: 5px;
          }
        }
        .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
          margin-top: 3px;

          &:first-child {
            margin-left: 8px;
          }
        }
      }
    }
  }

  ${({ value }) => {
    return (!Array.isArray(value) && value !== undefined && value !== null) || (Array.isArray(value) && value.length > 0)
      ? css`
          box-shadow: none !important;

          .ant-select-selector {
            box-shadow: inset 4px 4px 4px 0px ${(x) => x.theme.colors.lavenderGray} !important;
          }

          &.ant-select-multiple.ant-select-status-error {
            .ant-select-selector {
              box-shadow: none !important;
            }
          }

          &.ant-select-multiple.ant-select-status-success {
            box-shadow: none !important;
            .ant-select-selector {
              box-shadow: inset 4px 4px 4px 0px ${(x) => x.theme.colors.lavenderGray} !important;
            }
          }

          .ant-select-arrow {
            background: transparent;
          }
        `
      : null;
  }}

  ${({ templates }) =>
    templates?.includes('filter') &&
    css`
      width: 200px;

      &:not(:hover) {
        box-shadow: none !important;
      }

      .ant-select-selector {
        box-shadow: none !important;
        border: 1px solid ${(x) => x.theme.colors.lavenderGray} !important;
        border-radius: 10px !important;
      }
    `}

    ${({ templates }) =>
    templates?.includes('device') &&
    css`
      width: 100%;
      box-shadow: none !important;

      .ant-select-selector {
        padding-left: 35px !important;
        box-shadow: none !important;
        border-radius: 10px !important;

        .ant-select-selection-search {
          left: 35px !important;
        }
      }
    `}

    
    ${({ templates }) =>
    templates?.includes('full-width') &&
    css`
      width: 100%;
    `}
    
    ${({ templates }) =>
    templates?.includes('width-500') &&
    css`
      width: 500px;
    `}
    
    ${({ templates }) =>
    templates?.includes('width-300') &&
    css`
      width: 300px;
    `}

    ${({ value, templates }) =>
    value !== undefined &&
    value !== null &&
    templates?.includes('filter') &&
    css`
      .ant-select-selector {
        border: 1px solid ${(x) => x.theme.colors.mediumSpringGreen} !important;
      }
    `}
`;
