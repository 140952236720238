import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { Page } from '../../components/globals';
import GlobalDashboard from '../../components/pages/dashboard/GlobalDashboard';
import ManagerDashboard from '../../components/pages/dashboard/ManagerDashboard';
import { UserRoleType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';

export default function Dashboard() {
  // Definitions
  const { t } = useTranslation();

  const [user] = useCacheUser();

  // Components
  const Body = () => {
    switch (user.u) {
      case UserRoleType.Owner:
      case UserRoleType.Administrator:
        return <ManagerDashboard />;

      default:
        return <GlobalDashboard />;
    }
  };

  // Render
  return (
    <Page icon={<FaHome />} title={t('Home')}>
      {user && <Body />}
    </Page>
  );
}
