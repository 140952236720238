const LanguageTypes = [
  {
    value: 0,
    name: 'English',
    displayName: 'LanguageEnglish',
    group: null,
    shortName: null,
    description: null,
    prompt: 'Default',
    order: 100,
    data: {
      code: 'en',
      locale: 'en'
    }
  },
  {
    value: 1,
    name: 'Russian',
    displayName: 'LanguageRussian',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      code: 'ru',
      locale: 'ru'
    }
  },
  {
    value: 2,
    name: 'Spanish',
    displayName: 'LanguageSpanish',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      code: 'es',
      locale: 'es'
    }
  },
  {
    value: 3,
    name: 'Serbian',
    displayName: 'LanguageSerbian',
    group: 'Latin',
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      code: 'sr',
      locale: 'sr'
    }
  },
  {
    value: 4,
    name: 'Taiwan',
    displayName: 'LanguageTaiwan',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      code: 'tw',
      locale: 'zh-TW'
    }
  },
  {
    value: 5,
    name: 'Kazakh',
    displayName: 'LanguageKazakh',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {
      code: 'kk',
      locale: 'kk'
    }
  }
];

export default LanguageTypes;