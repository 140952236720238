import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHandHoldingMedical, FaPlus } from 'react-icons/fa';
import { Button, Drawer, Extra, Page, Select, Table } from '../../components/globals';
import VitalCreateUpdate from '../../components/pages/vital/VitalCreateUpdate';
import { ActionType, CountryTypes, UserRoleActionType, UserRoleType, VitalColumns } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { userList, vitalDelete, vitalList } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';

export default function Vitals() {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [users, setUsers] = useState([]);
  const [response, setResponse] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [panelVisibility, setPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({ list: true });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: user.u === UserRoleType.Patient ? user.i : null,
    userRole: UserRoleType.Patient,
    includeUser: user.u !== UserRoleType.Patient,
  });

  const [userFilter] = useState({
    pageNumber: 1,
    dataLimit: 9999,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: user.i,
    userRole: UserRoleType.Patient,
  });

  const isAllCountry = user.checkAction(ActionType.VitalAllCountry);

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await vitalList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  const getTemplates = () => {
    if (isAllCountry) return 'default-1-filter-2';

    return 'default-0-filter-2';
  };

  // Events
  const onCreateClick = async () => {
    if (user.u !== UserRoleType.Patient) {
      await userList(userFilter, (status, res) => {
        if (status) {
          setUsers(res.data);
        }
      });
    }

    setRowData(null);
    setIsPreview(false);
    setPanelVisibility(true);
  };

  const onUpdateClick = async (row) => {
    setRowData(row);
    setIsPreview(false);
    setPanelVisibility(true);
  };

  const onDetailClick = async (row) => {
    setRowData(row);
    setIsPreview(true);
    setPanelVisibility(true);
  };

  const onDeleteClick = async (row) => {
    setPageState((x) => ({ ...x, delete: false }));

    await vitalDelete({ id: row.id, userId: user.i, country: filter.country, userRole: filter.userRole }, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('DeleteCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const load = () => {
    list();
    setPanelVisibility(false);
  };

  // Render
  return (
    <Page
      title={t('Vitals')}
      icon={<FaHandHoldingMedical />}
      subTitle={
        user.checkAction(ActionType.VitalCreate) &&
        (user.u === UserRoleType.Patient || user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List)) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Vitals'),
          breadcrumbName: t('Vitals'),
          icon: <FaHandHoldingMedical />,
        },
      ]}
      templates={getTemplates()}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            value={filter.country}
            templates={['filter']}
            allowClear={false}
          />
        ),
        <Extra key="filter" pageState={pageState} filter={filter} setFilter={setFilter} />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={VitalColumns({
          isDeleted: filter.isDeleted,
          isUserVisible: filter.includeUser,
          onUpdateClick: user.checkAction(ActionType.VitalUpdate) && onUpdateClick,
          onDeleteClick: user.checkAction(ActionType.VitalDelete) && onDeleteClick,
          onDetailClick: user.checkAction(ActionType.VitalList) && (!user.checkAction(ActionType.VitalUpdate) || filter.isDeleted) && onDetailClick,
        })}
      />

      <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
        {panelVisibility && <VitalCreateUpdate isPreview={isPreview} data={rowData} country={filter.country} users={users} load={load} />}
      </Drawer>
    </Page>
  );
}
