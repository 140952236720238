import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { ContentTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { contentDetailGlobal, contentUserDetail } from '../../../services/api';
import { Select } from '../../globals';

export default function ContentPreview({ parameter, id, onWidthChange }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);
  const [design, setDesign] = useState(0);

  const [contentFilter] = useState({
    pageNumber: 1,
    dataLimit: 3,
    country: user.c,
    userCountry: user.c,
    includeChildren: true,
  });

  const [contentUserFilter] = useState({
    includeSelections: true,
    userId: parameter.id,
  });

  // Hooks
  useEffect(() => {
    load();
  }, [id]);

  // Functions
  const load = async () => {
    let [contentDetailResponse, contentUserListResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        contentDetailGlobal({ ...contentFilter, id }, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        contentUserDetail({ ...contentUserFilter, parentContentId: id }, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    if (!contentDetailResponse) {
      setResponse(null);
      return;
    }

    let result = contentDetailResponse.data;
    result.typeData = ContentTypes.find((x) => x.value === result.type);

    if (contentUserListResponse?.data?.selections?.length > 0) {
      result.userSelection = contentUserListResponse.data;

      result.children = result.children.map((question) => {
        question.children = question.children.map((answer) => {
          if (contentUserListResponse.data.selections.includes(answer.id)) {
            answer.checked = true;
          }

          return answer;
        });

        return question;
      });
    }

    console.log(result);
    setResponse(result);
  };

  // Events
  const onDesignChange = (value) => {
    setDesign(value);
    if (onWidthChange) {
      onWidthChange(value);
    }
  };

  return response ? (
    <Container>
      <Header>
        <Left>
          <Title>{response.title}</Title>

          <ScoreText>
            {t('TotalPoint')}: <strong>{response.userSelection.totalPoint}</strong>
          </ScoreText>
        </Left>
        <Right>
          <Select
            key="design-filter"
            data={[
              {
                text: t('PreviewFullDetailDesign'),
                value: 0,
              },
              {
                text: t('PreviewQuestionAnswerDesign'),
                value: 1,
              },
              {
                text: t('PreviewQuestionAnswerPointDesign'),
                value: 2,
              },
            ]}
            allowClear={false}
            onChange={onDesignChange}
            placeholder={t('SelectDesign')}
            value={design}
            templates={['filter', 'width-300']}
          />
        </Right>
      </Header>

      {response.children && (
        <Items>
          {response.children.map((question, qIndex) => (
            <Item key={`question-${qIndex}`}>
              <Question>
                <QuestionTitle>{question.title}</QuestionTitle>
                {design === 0 && <QuestionBody dangerouslySetInnerHTML={{ __html: question.body }} />}
              </Question>
              {question.children && (
                <Answers>
                  {question.children.map((answer, aIndex) => (
                    <Answer key={`answer-${aIndex}`} checked={answer.checked} correct={answer.isCorrect}>
                      {design < 2 && <AnswerTitle>{answer.title}</AnswerTitle>}
                      {answer.point > 0 && (
                        <Point>
                          <PointTitle>{t('Point')}:</PointTitle>
                          <PointText>{answer.point}</PointText>
                        </Point>
                      )}
                    </Answer>
                  ))}
                </Answers>
              )}
            </Item>
          ))}
        </Items>
      )}
    </Container>
  ) : (
    <Skeleton />
  );
}

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Left = styled.div``;

const Right = styled.div``;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const ScoreText = styled.div``;

const Items = styled.div``;

const Item = styled.div`
  margin-top: 24px;
`;

const Question = styled.div`
  margin-bottom: 8px;
`;

const QuestionTitle = styled.div`
  font-weight: bold;
`;

const QuestionBody = styled.div``;

const Answers = styled.div`
  display: flex;
`;

const Answer = styled.div`
  margin-right: 12px;
  border: 2px solid ${(x) => x.theme.colors.darkTurquoise};
  padding: 5px 10px;
  border-radius: 10px;

  ${({ correct, checked }) =>
    checked && correct
      ? css`
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
          color: ${(x) => x.theme.colors.white};
        `
      : checked &&
        css`
          border: 2px solid ${(x) => x.theme.colors.rustyRed};
          background-color: ${(x) => x.theme.colors.rustyRed};
          color: ${(x) => x.theme.colors.white};
        `}

  ${({ correct }) =>
    correct &&
    css`
      border: 2px solid ${(x) => x.theme.colors.mediumSpringGreen};
    `}
`;

const Point = styled.div`
  display: flex;
  opacity: 0.8;
`;

const PointTitle = styled.div``;

const PointText = styled.div`
  font-weight: bold;
  margin-left: 8px;
`;

const AnswerTitle = styled.div``;
