const NotificationDomainTypes = [
  {
    value: 0,
    name: 'Treatment',
    displayName: 'NotificationDomainTreatment',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Conference',
    displayName: 'NotificationDomainConference',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'Content',
    displayName: 'NotificationDomainContent',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'WAMS',
    displayName: 'NotificationDomainWAMS',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  }
];

export default NotificationDomainTypes;