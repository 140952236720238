import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { v4 as uuidv4 } from 'uuid';
import {
  ContentGroupTypes,
  ContentType,
  ContentTypes,
  CountryTypes,
  LanguageTypes,
  RoleFieldDiseaseTypes,
  RoleFieldTherapeuticAreaTypes,
  UserRoleTypes,
} from '../../constants';

export default function ContentInputs({ country, isAllCountry, parentId, parentContentType, contentType, isCreate, onContentTypeChange, onChangeClick, onDeleteClick }) {
  const { t } = useTranslation();

  const getContentTypes = () => {
    if (!isCreate) return ContentTypes;

    switch (parentContentType) {
      case ContentType.Survey:
      case ContentType.Course:
      case ContentType.FAQ:
        return ContentTypes.filter((x) => x.value === ContentType.Question);

      case ContentType.Question:
        return ContentTypes.filter((x) => x.value === ContentType.Answer);

      default:
        return ContentTypes.filter((x) => x.group === 'Parent');
    }
  };

  //#region [Default Values]
  let result = [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'parentId',
      initialValue: parentId,
    },
    {
      type: 'select',
      name: 'type',
      label: t('ContentType'),
      placeholder: t('SelectContentType'),
      rules: [{ required: true, message: t('ContentTypeEmpty') }],
      data: getContentTypes().map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onContentTypeChange,
      disabled: !isCreate,
    },
    contentType === ContentType.Static && {
      type: 'select',
      name: 'contentGroup',
      label: t('ContentGroup'),
      placeholder: t('SelectContentGroup'),
      data: ContentGroupTypes.filter((x) => x.group.split(',').some((s) => s === 'Static')).map((x) => ({ ...x, text: t(x.displayName) })),
      disabled: !isCreate,
    },
    {
      type: 'select',
      name: 'language',
      label: t('LanguageType'),
      placeholder: t('SelectLanguageType'),
      rules: [{ required: true, message: t('LanguageTypeEmpty') }],
      data: LanguageTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      disabled: !isCreate,
    },
    {
      type: 'select',
      name: 'countries',
      mode: 'multiple',
      label: t('Countries'),
      placeholder: t('SelectCountry'),
      rules: [{ required: true, message: t('CountryEmpty') }],
      data: CountryTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: isAllCountry ? [] : [country],
      disabled: !isAllCountry,
    },
    {
      type: 'select',
      name: 'userRoles',
      mode: 'multiple',
      label: t('UserRoles'),
      placeholder: t('SelectUserRole'),
      data: UserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'select',
      name: 'diseases',
      mode: 'multiple',
      label: t('Diseases'),
      placeholder: t('SelectDisease'),
      data: RoleFieldDiseaseTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'select',
      name: 'therapeuticAreas',
      mode: 'multiple',
      label: t('TherapeuticAreas'),
      placeholder: t('SelectTherapeuticArea'),
      data: RoleFieldTherapeuticAreaTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'input',
      name: 'title',
      label: t('Title'),
      placeholder: t('TitleEmpty'),
      rules: [
        { required: true, message: t('TitleEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
    {
      type: 'hidden',
      name: 'url',
      label: t('Url'),
      placeholder: t('ContentUrlEmpty'),
      rules: [
        { required: true, message: t('ContentUrlEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
      initialValue: uuidv4(),
    },
  ];
  //#endregion

  //#region [Optional Values]
  if (contentType !== ContentType.Question && contentType !== ContentType.Answer) {
    result = [
      ...result,
      {
        type: 'input',
        name: 'description',
        label: t('Description'),
        placeholder: t('DescriptionEmpty'),
        rules: [
          { required: true, message: t('DescriptionEmpty') },
          { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
        ],
      },
    ];
  }

  result = [
    ...result,
    {
      type: 'input',
      name: 'thumbnailImageUrl',
      label: t('ThumbnailImageUrl'),
      placeholder: t('ThumbnailImageUrlEmpty'),
      storage: true,
      imagePreview: true,
      allowClear: false,
      readOnly: true,
      onChangeClick: () => onChangeClick('thumbnailImageUrl'),
      onDeleteClick: () => onDeleteClick('thumbnailImageUrl'),
      deletePopupTitle: t('AreYouSureWantToDelete'),
      deleteConfirmTitle: t('Confirm'),
      deleteCancelTitle: t('Cancel'),
    },
    {
      type: 'input',
      name: 'detailImageUrl',
      label: t('DetailImageUrl'),
      placeholder: t('DetailImageUrlEmpty'),
      storage: true,
      imagePreview: true,
      allowClear: false,
      readOnly: true,
      onChangeClick: () => onChangeClick('detailImageUrl'),
      onDeleteClick: () => onDeleteClick('detailImageUrl'),
      deletePopupTitle: t('AreYouSureWantToDelete'),
      deleteConfirmTitle: t('Confirm'),
      deleteCancelTitle: t('Cancel'),
    },
  ];
  //#endregion

  //#region [Video]
  if (contentType === ContentType.Video) {
    result = [
      ...result,
      {
        type: 'input',
        name: 'mediaUrl',
        label: t('MediaUrl'),
        placeholder: t('MediaUrlEmpty'),
        rules: [{ required: true, message: t('MediaUrlEmpty') }],
        storage: true,
        onChangeClick: () => onChangeClick('mediaUrl'),
        onDeleteClick: () => onDeleteClick('mediaUrl'),
        deletePopupTitle: t('AreYouSureWantToDelete'),
        deleteConfirmTitle: t('Confirm'),
        deleteCancelTitle: t('Cancel'),
      },
    ];
  }
  //#endregion

  //#region [Podcast]
  if (contentType === ContentType.Podcast) {
    result = [
      ...result,
      {
        type: 'input',
        name: 'mediaUrl',
        label: t('MediaUrl'),
        placeholder: t('MediaUrlEmpty'),
        rules: [{ required: true, message: t('MediaUrlEmpty') }],
        storage: true,
        imagePreview: false,
        onChangeClick: () => onChangeClick('mediaUrl'),
        onDeleteClick: () => onDeleteClick('mediaUrl'),
        deletePopupTitle: t('AreYouSureWantToDelete'),
        deleteConfirmTitle: t('Confirm'),
        deleteCancelTitle: t('Cancel'),
      },
      {
        type: 'number',
        name: 'duration',
        label: t('DurationInSeconds'),
        placeholder: t('DurationEmpty'),
        rules: [{ required: true, message: t('DurationEmpty') }],
        initialValue: 0,
      },
    ];
  }
  //#endregion

  //#region [Document]
  if (contentType === ContentType.Document) {
    result = [
      ...result,
      {
        type: 'input',
        name: 'mediaUrl',
        label: t('MediaUrl'),
        placeholder: t('MediaUrlEmpty'),
        rules: [{ required: true, message: t('MediaUrlEmpty') }],
        storage: true,
        imagePreview: false,
        onChangeClick: () => onChangeClick('mediaUrl'),
        onDeleteClick: () => onDeleteClick('mediaUrl'),
        deletePopupTitle: t('AreYouSureWantToDelete'),
        deleteConfirmTitle: t('Confirm'),
        deleteCancelTitle: t('Cancel'),
      },
    ];
  }
  //#endregion

  //#region [Survey]
  if (contentType === ContentType.Survey && parentId === undefined) {
    result = [
      ...result,
      {
        type: 'number',
        name: 'reminderDays',
        label: t('ReminderDays'),
        placeholder: t('ReminderDaysEmpty'),
        rules: [{ required: true, message: t('ReminderDaysEmpty') }],
        initialValue: 0,
      },
    ];
  }

  if (contentType === ContentType.Survey && parentId === undefined) {
    result = [
      ...result,
      {
        type: 'select',
        name: 'isAttachConference',
        label: t('AttachConference'),
        placeholder: t('SelectAttachConference'),
        rules: [{ required: true, message: t('AttachConferenceEmpty') }],
        data: [
          { value: true, text: t('Yes') },
          { value: false, text: t('No') },
        ],
        initialValue: true,
      },
    ];
  }

  //#endregion

  //#region [Question]
  if (contentType === ContentType.Question && parentContentType !== ContentType.FAQ) {
    result = [
      ...result,
      {
        type: 'select',
        name: 'isMandatory',
        label: t('QuestionIsMandatory'),
        placeholder: t('SelectQuestionIsMandatory'),
        rules: [{ required: true, message: t('QuestionIsMandatoryEmpty') }],
        data: [
          { value: true, text: t('Yes') },
          { value: false, text: t('No') },
        ],
        initialValue: true,
      },
    ];
  }
  //#endregion

  //#region [Answer]
  if (contentType === ContentType.Answer) {
    result = [
      ...result,
      {
        type: 'number',
        name: 'point',
        label: t('Point'),
        placeholder: t('PointEmpty'),
        rules: [{ required: true, message: t('PointEmpty') }],
        initialValue: 0,
      },
      {
        type: 'select',
        name: 'contentGroup',
        label: t('ContentGroup'),
        placeholder: t('SelectContentGroup'),
        data: ContentGroupTypes.filter((x) => x.group.split(',').some((s) => s === 'Answer')).map((x) => ({ ...x, text: t(x.displayName) })),
        initialValue: 0,
      },
      {
        type: 'select',
        name: 'isCorrect',
        label: t('AnswerIsCorrect'),
        placeholder: t('SelectAnswerIsCorrect'),
        rules: [{ required: true, message: t('AnswerIsCorrectEmpty') }],
        data: [
          { value: true, text: t('Yes') },
          { value: false, text: t('No') },
        ],
        initialValue: true,
      },
    ];
  }
  //#endregion

  //#region [Default]
  result = [
    ...result,
    {
      type: 'date',
      name: 'expireTime',
      label: t('ExpireTime'),
      disabledDate: (current) => current && current < moment().endOf('day'),
    },
    {
      type: 'date',
      name: 'contentTime',
      label: t('ContentTime'),
      rules: [{ required: true, message: t('ContentTimeEmpty') }],
      initialValue: moment(),
    },
    {
      type: 'select',
      name: 'isActive',
      label: t('Activity'),
      placeholder: t('SelectActivity'),
      rules: [{ required: true, message: t('IsActiveEmpty') }],
      data: [
        { value: true, text: t('Active') },
        { value: false, text: t('Passive') },
      ],
      initialValue: true,
    },
    {
      type: 'number',
      name: 'row',
      label: t('ContentRow'),
      placeholder: t('ContentRowEmpty'),
      rules: [{ required: true, message: t('ContentRowEmpty') }],
      initialValue: 0,
    },
  ];

  if (contentType !== ContentType.Answer) {
    result = [
      ...result,
      {
        type: 'editor',
        name: 'body',
        label: t('ContentBody'),
        columnSize: 24,
      },
    ];
  }
  //#endregion

  return result;
}
