const TemplateTypes = [
  {
    value: 0,
    name: 'CreatePassword',
    displayName: 'CreatePasswordTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {
      groups: [
        1
      ]
    }
  },
  {
    value: 1,
    name: 'ForgotPassword',
    displayName: 'ForgotPasswordTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      groups: [
        1
      ]
    }
  },
  {
    value: 2,
    name: 'LoginTemplate',
    displayName: 'LoginTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      groups: [
        1
      ]
    }
  },
  {
    value: 3,
    name: 'UserInvite',
    displayName: 'UserInviteTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      groups: [
        1
      ]
    }
  },
  {
    value: 4,
    name: 'DeliveryStepFlow',
    displayName: 'DeliveryStepFlowTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      groups: [
        1
      ]
    }
  },
  {
    value: 5,
    name: 'RoleNotificationTemplate',
    displayName: 'RoleNotificationTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      groups: [
        1
      ]
    }
  },
  {
    value: 6,
    name: 'AskForHelp',
    displayName: 'AskForHelpTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {
      groups: [
        1
      ]
    }
  },
  {
    value: 7,
    name: 'AdverseEvent',
    displayName: 'AdverseEventTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 800,
    data: {
      groups: [
        1
      ]
    }
  },
  {
    value: 8,
    name: 'AskForHelpTechnicalDisclaimer',
    displayName: 'AskForHelpTechnicalDisclaimerTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 900,
    data: {
      groups: [
        2
      ]
    }
  },
  {
    value: 9,
    name: 'AskForHelpSupplyDeliveryDisclaimer',
    displayName: 'AskForHelpSupplyDeliveryDisclaimerTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1000,
    data: {
      groups: [
        2
      ]
    }
  },
  {
    value: 10,
    name: 'AskForHelpReportPotentialSideEffectDisclaimer',
    displayName: 'AskForHelpReportPotentialSideEffectDisclaimerTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1100,
    data: {
      groups: [
        2
      ]
    }
  },
  {
    value: 11,
    name: 'AskForHelpRequestProductInformationDisclaimer',
    displayName: 'AskForHelpRequestProductInformationDisclaimerTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1200,
    data: {
      groups: [
        2
      ]
    }
  },
  {
    value: 12,
    name: 'AskForHelpReportAPotentialProductDefectDisclaimer',
    displayName: 'AskForHelpReportAPotentialProductDefectDisclaimerTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1300,
    data: {
      groups: [
        2
      ]
    }
  },
  {
    value: 13,
    name: 'AdverseEventDisclaimer',
    displayName: 'AdverseEventDisclaimerTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1400,
    data: {
      groups: [
        2
      ]
    }
  }
];

export default TemplateTypes;