import { Timeline } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { DeliveryActionType, DeliveryStatusType, DeliveryStepStatusType, UserRoleTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { Modal } from '../../globals';
import DeliverySuggestions from './DeliverySuggestions';
import ConfirmDelivery from './step/ConfirmDelivery';
import EnterDeliveryDate from './step/EnterDeliveryDate';
import EnterPatientVitals from './step/EnterPatientVitals';
import EnterVisitDate from './step/EnterVisitDate';
import RedirectDelivery from './step/RedirectDelivery';

export default function DeliveryStep({ country, delivery, step, flows, onCompleted, row }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [suggestionPanelVisibility, setSuggestionPanelVisibility] = useState(false);
  const [suggestionUserRoleDatas, setSuggestionUserRoleDatas] = useState({});

  // Events
  const onError = (errors) => {
    if (errors) {
      let relationError = errors.find((x) => x.code === 'DeliveryUserRelationNotFoundException');
      if (relationError) {
        let userRoleDatas = relationError.message.split(',').map((x) => UserRoleTypes.find((u) => u.value === parseInt(x)));
        setSuggestionUserRoleDatas(userRoleDatas);
        setSuggestionPanelVisibility(true);
      }
    }
  };

  // Components
  const StepAction = () => {
    let next = flows.find((x) => x.parentId === step.deliveryFlowStepId);

    switch (step.action) {
      case DeliveryActionType.RedirectDelivery:
        let redirects = flows.filter((x) => x.parentId === step.deliveryFlowStepId);
        return <RedirectDelivery redirects={redirects} country={country} delivery={delivery} step={step} complete={onCompleted} onError={onError} />;

      case DeliveryActionType.ConfirmDelivery:
        return <ConfirmDelivery next={next} country={country} delivery={delivery} step={step} complete={onCompleted} onError={onError} />;

      case DeliveryActionType.EnterDeliveryDate:
        return <EnterDeliveryDate next={next} country={country} delivery={delivery} step={step} complete={onCompleted} onError={onError} />;

      case DeliveryActionType.EnterVisitDate:
        return <EnterVisitDate next={next} country={country} delivery={delivery} step={step} complete={onCompleted} onError={onError} />;

      case DeliveryActionType.EnterPatientVitals:
        return <EnterPatientVitals next={next} country={country} delivery={delivery} step={step} complete={onCompleted} onError={onError} />;

      default:
        return <>{t('NotImplemented')}</>;
    }
  };

  // Render
  return (
    step && (
      <Container row={row} status={step.status} dstatus={delivery.status}>
        {step.status === DeliveryStepStatusType.Approved ? (
          <Detail>
            <Title>{step.completedNameKey}</Title>
            {step.completedDescriptionKey && <Description>{step.completedDescriptionKey}</Description>}
            <Date>{step.creationTime.format('llll')}</Date>
          </Detail>
        ) : (
          <Detail>
            <Title>{step.nameKey}</Title>
            {step.descriptionKey && <Description>{step.descriptionKey}</Description>}
            <Date>{step.creationTime.format('llll')}</Date>
          </Detail>
        )}

        {delivery.status === DeliveryStatusType.Pending && step.status === DeliveryStepStatusType.Pending && step.userRole === user.u && (
          <StepActionContainer>
            <StepAction />
          </StepActionContainer>
        )}

        <Modal
          width={500}
          title={t('Suggestions')}
          visible={suggestionPanelVisibility}
          onCancelClick={() => setSuggestionPanelVisibility(false)}
          cancelText={t('Confirm')}
          templates={['colored']}
        >
          <DeliverySuggestions suggestionUserRoleDatas={suggestionUserRoleDatas} isDeliveryUserSelection={true} />
        </Modal>
      </Container>
    )
  );
}

const Container = styled(Timeline.Item)`
  ${({ row }) =>
    row === 'last' &&
    css`
      .ant-timeline-item-tail {
        display: none;
      }
    `}

  .ant-timeline-item-content {
    top: 0px;
    margin: 0 0 0 26px;
  }

  .ant-timeline-item-tail {
    left: 6px;
    z-index: 10;
    border-left: 3px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
  }

  .ant-timeline-item-head {
    width: 15px;
    height: 15px;
    z-index: 20;
    border: 3px solid transparent;

    ${({ status, row, dstatus }) => {
      if (dstatus === DeliveryStatusType.Rejected && row === 'last') {
        return css`
          color: ${(x) => x.theme.colors.rustyRed};
          border-color: ${(x) => x.theme.colors.rustyRed};
        `;
      }

      switch (status) {
        case DeliveryStepStatusType.Approved:
          return css`
            color: ${(x) => x.theme.colors.mediumSpringGreen};
            border-color: ${(x) => x.theme.colors.mediumSpringGreen};
          `;
        default:
          return css`
            color: ${(x) => x.theme.colors.naplesYellow};
            border-color: ${(x) => x.theme.colors.naplesYellow};
          `;
      }
    }}
  }
`;

const Detail = styled.div``;

const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
`;

const Description = styled.div``;

const Date = styled.div`
  font-style: italic;
  font-size: 12px;
  color: ${(x) => x.theme.colors.deepKaomaru};
  opacity: 0.5;
`;

const StepActionContainer = styled.div`
  margin-top: 12px;
  max-width: 300px;
`;
