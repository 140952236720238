const TemplateType = {
  CreatePassword: 0,
  ForgotPassword: 1,
  LoginTemplate: 2,
  UserInvite: 3,
  DeliveryStepFlow: 4,
  RoleNotificationTemplate: 5,
  AskForHelp: 6,
  AdverseEvent: 7,
  AskForHelpTechnicalDisclaimer: 8,
  AskForHelpSupplyDeliveryDisclaimer: 9,
  AskForHelpReportPotentialSideEffectDisclaimer: 10,
  AskForHelpRequestProductInformationDisclaimer: 11,
  AskForHelpReportAPotentialProductDefectDisclaimer: 12,
  AdverseEventDisclaimer: 13
};

export default TemplateType;