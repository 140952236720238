import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Spin } from 'antd';
import styled, { css } from 'styled-components';
import { Popover } from '../../globals';

export default function CardBase({ information, title, right, templates, height, width, ready, children }) {
  return (
    <Container ready={ready?.toString()} templates={templates} height={height} width={width} bordered={false}>
      <Header templates={templates}>
        {information && (
          <Information>
            <Popover placement="bottomLeft" content={information}>
              <InfoCircleOutlined />
            </Popover>
          </Information>
        )}
        {title && <Title>{title}</Title>}
        {right && <Right>{right}</Right>}
      </Header>

      {!ready && (
        <FormSpinContainer>
          <FormSpin size="large" spinning={true} />
        </FormSpinContainer>
      )}

      <Content ready={ready?.toString()} templates={templates}>
        {children}
      </Content>
    </Container>
  );
}

const LoginCard = css`
  ${({ templates }) =>
    templates?.includes('login') &&
    css`
      position: relative;
      width: 100%;
      max-width: 500px;
      border: 0px;
      background-color: ${(x) => x.theme.colors.antiFlashWhite};
      border-radius: ${(x) => x.theme.global.borderRadius};
    `}
`;

const NotFoundCard = css`
  ${({ templates }) =>
    templates?.includes('not-found') &&
    css`
      width: 100%;
      max-width: 300px;
      border: 0px;
      background-color: ${(x) => x.theme.colors.antiFlashWhite};
      border-radius: ${(x) => x.theme.global.borderRadius};
      overflow: hidden;
    `}
`;

const CalculatorCard = css`
  ${({ templates }) =>
    templates?.includes('calculator') &&
    css`
      width: calc(100% - 30px);
      border: 0px;
      background-color: ${(x) => x.theme.colors.antiFlashWhite};
      border-radius: ${(x) => x.theme.global.borderRadius};
      overflow: hidden;
    `}
`;

const PageCard = css`
  ${({ templates }) =>
    templates?.includes('page') &&
    css`
      border: 0px;
      .ant-card-body {
        padding: 0px;
      }
    `}
`;

const WidgetCard = css`
  ${({ templates }) =>
    templates?.includes('widget') &&
    css`
      overflow: hidden;
      border-radius: ${(x) => x.theme.global.borderRadius};
      box-shadow: 0px 0px 6px 0px ${(x) => x.theme.colors.lavenderGray};

      &:hover {
        box-shadow: 0px 0px 12px 0px ${(x) => x.theme.colors.lavenderGray};
      }

      .ant-card-body {
        height: 100%;
        padding: 0px;
      }

      @media only screen and (max-width: 1700px) {
        width: calc(50% - 8px);
      }

      @media only screen and (max-width: 1200px) {
        width: calc(100%);
      }
    `}
`;

const ScoreWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('score') &&
    css`
      height: 500px;
      width: calc(33.33% - 16px);

      @media only screen and (max-width: 600px) {
        height: 300px;
      }
    `}
`;

const FullWidthWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('full-width') &&
    css`
      width: 100% !important;
    `}
`;

const Width50WidgetCard = css`
  ${({ templates }) =>
    templates?.includes('width-50') &&
    css`
      width: 50%;
    `}
`;

const ScoreChartWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('score-chart') &&
    css`
      height: 500px;
      width: calc(100% - 33.33%);

      @media only screen and (max-width: 600px) {
        height: 300px;
      }
    `}
`;

const VisitWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('visit') &&
    css`
      margin-bottom: 24px;
    `}
`;

const VitalWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('vital') &&
    css`
      margin-bottom: 24px;
    `}
`;

const TreatmentDeliveryCard = css`
  ${({ templates }) =>
    templates?.includes('treatment-delivery') &&
    css`
      margin-bottom: 24px;
    `}
`;

const ManagerDashboardWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('manager-widget') &&
    css`
      height: 500px;
      width: calc(33.33% - 16px);
      margin-bottom: 24px;
      border-radius: ${(x) => x.theme.global.borderRadius};
      box-shadow: 0px 0px 6px 0px ${(x) => x.theme.colors.lavenderGray};

      @media only screen and (max-width: 1700px) {
        width: calc(50% - 8px);
      }

      @media only screen and (max-width: 1200px) {
        width: calc(100%);
      }

      @media only screen and (max-width: 600px) {
        height: 300px;
      }
    `}
`;

const CalendarWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('calendar') &&
    css`
      margin-bottom: 24px;
    `}
`;

const TreatmentUsageWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('treatment-usage') &&
    css`
      height: 100%;
      margin-bottom: 0px;
    `}
`;

const DoseCalculatorWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('dose-calculator') &&
    css`
      height: 500px;
      width: calc(33.33% - 28px);

      @media only screen and (max-width: 600px) {
        height: 300px;
      }
    `}
`;

const ConferenceWidgetCard = css`
  ${({ templates }) =>
    templates?.includes('conference') &&
    css`
      height: 500px;
      width: calc(33.33% - 16px);

      @media only screen and (max-width: 600px) {
        height: 300px;
      }
    `}
`;

const StaticContentCard = css`
  ${({ templates }) =>
    templates?.includes('static-content') &&
    css`
      margin: 5%;
    `}
`;

const HideCard = css`
  ${({ templates }) =>
    templates?.includes('hide') &&
    css`
      display: none;
    `}
`;

const Container = styled(Card)`
  ${PageCard}
  ${LoginCard}
  ${NotFoundCard}
  ${WidgetCard}
  ${ScoreWidgetCard}
  ${ScoreChartWidgetCard}
  ${ManagerDashboardWidgetCard}
  ${CalendarWidgetCard}
  ${DoseCalculatorWidgetCard}
  ${ConferenceWidgetCard}
  ${VisitWidgetCard}
  ${FullWidthWidgetCard}
  ${StaticContentCard}
  ${TreatmentUsageWidgetCard}
  ${Width50WidgetCard}
  ${TreatmentDeliveryCard}
  ${HideCard}
  ${VitalWidgetCard}
  ${CalculatorCard}
`;

const Header = styled.div`
  display: none;

  ${({ templates }) =>
    templates?.includes('with-header') &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;
      height: 60px;
    `}

  ${({ templates }) =>
    templates?.includes('header-information') &&
    css`
      padding-left: 48px;
    `}

    ${({ templates }) =>
    templates?.includes('header-colored') &&
    css`
      color: ${(x) => x.theme.colors.white};
      background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);
      a {
        color: ${(x) => x.theme.colors.white};

        &:hover {
          text-decoration: none;
          color: ${(x) => x.theme.colors.white};
        }
      }
    `}

      ${({ templates }) =>
    templates?.includes('header-gray') &&
    css`
      background: ${(x) => x.theme.colors.antiFlashWhiteDark};
    `}
`;

const Information = styled.div`
  position: absolute;
  left: 19px;
  font-size: 20px;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;

  @media only screen and (max-width: 1900px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const Right = styled.div`
  a {
    display: flex;
    align-items: center;
    svg {
      font-size: 32px;
    }
  }
`;

const Content = styled.div`
  opacity: 0;
  height: 100%;
  transition: opacity ease-in-out 0.3s;

  ${({ templates }) =>
    templates?.includes('with-header') &&
    css`
      height: calc(100% - 60px);
    `}

  ${({ templates }) =>
    templates?.includes('treatment-delivery') &&
    css`
      padding: 0px 12px;
    `}

  ${({ ready }) =>
    ready === 'true' &&
    css`
      opacity: 1;
    `}

      ${({ templates }) =>
    templates?.includes('treatment-usage') &&
    css`
      padding: 0px 12px;
    `}

    ${({ templates }) =>
    templates?.includes('calendar') &&
    css`
      padding: 12px;
    `}

    ${({ templates }) =>
    templates?.includes('dose-calculator') &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 12px;
    `}

    ${({ templates }) =>
    templates?.includes('visit') &&
    css`
      padding: 0px 12px;
    `}

  ${({ templates }) =>
    templates?.includes('vital') &&
    css`
      padding: 0px 12px;
    `}
`;

const FormSpinContainer = styled.div`
  min-height: 1px;
`;

const FormSpin = styled(Spin)`
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 99;
  display: none;
  background-color: ${(x) => x.theme.colors.antiFlashWhite};
  border-radius: 10px;
  &.ant-spin-spinning {
    display: flex;
  }
  .ant-spin-dot {
    font-size: 60px;

    .ant-spin-dot-item {
      background-color: ${(x) => x.theme.colors.darkTurquoise};
    }
  }
`;
