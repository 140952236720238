import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ActionType, NoticeAdverseEventInputs, NoticeGroupType, TemplateGroupType, TemplateType, UserRoleActionType, UserRoleType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { noticeCreate, templateDetailContent, userList } from '../../../services/api';
import { isMobile } from '../../../utilies/deviceHelper';
import { translate } from '../../../utilies/localization';
import { notifySuccess } from '../../../utilies/notification';
import { convertUrl, isUrl } from '../../../utilies/urlHelper';
import { Drawer, Element } from '../../globals';

export default function AdverseEvent({ visibility, setVisibility }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const [user] = useCacheUser();

  const [users, setUsers] = useState([]);
  const [content, setContent] = useState([]);
  const [pageState, setPageState] = useState({ form: true });

  const [filter] = useState({
    pageNumber: 1,
    dataLimit: 9999,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: user.c,
    userRole: UserRoleType.Patient,
  });

  const [templateFilter, setTemplateFilter] = useState({
    type: TemplateType.AdverseEventDisclaimer,
    group: TemplateGroupType.ContentTemplate,
    country: user.c,
  });

  // Hooks
  useEffect(() => {
    if (visibility) {
      if (user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List)) {
        loadUser();
      }

      if (user.checkAction(ActionType.AdverseEventShowDisclaimer)) {
        loadContent();
      }
    }
  }, [visibility]);

  // Functions
  const loadUser = async () => {
    await userList(filter, (status, res) => {
      if (status) {
        setUsers(res.data);
      }
    });
  };

  const loadContent = async () => {
    await templateDetailContent(templateFilter, (status, res) => {
      if (status && res?.data) {
        let body = translate(res.data.content);

        let bodyText = convertUrl(body);
        if (isUrl(bodyText)) {
          setVisibility(false);
          setTemplateFilter((x) => ({ ...x, type: null }));

          if (window) {
            if (isMobile()) {
              window.location = bodyText;
            } else {
              window.open(bodyText, '_blank');
            }
          }

          return;
        }

        setContent(body);
      } else {
        setContent(null);
      }
    });
  };

  // Events
  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model = { ...model, country: user.c, group: NoticeGroupType.AdverseEvent };
    await noticeCreate(model, (status, res) => {
      if (status) {
        formRef.resetFields();
        notifySuccess(t('MessageSendCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Render
  return (
    <Drawer title={t('ReportAdverseEvent')} visible={visibility} onClose={() => setVisibility(false)}>
      {user.checkAction(ActionType.AdverseEventAllowSendReports) && (
        <Element
          key="notice-adverse-event-form"
          onFinish={onFinish}
          ready={pageState.form}
          formRef={formRef}
          inputs={NoticeAdverseEventInputs({ users })}
          columnSize={24}
          submit={t('Confirm')}
        />
      )}

      {content && <Disclaimer dangerouslySetInnerHTML={{ __html: content }} />}
    </Drawer>
  );
}

const Disclaimer = styled.div`
  margin-top: 24px;
`;
