import jwtDecode from 'jwt-decode';
import { ActionTypes, LanguageType } from '../constants';
import { useCache } from './useCache';

export function useCacheUser() {
  const [token, setToken] = useCache('tkn');

  const getValue = () => {
    if (!token) return null;
    let user = jwtDecode(token);
    if (!user) return null;

    if (user?.a !== undefined && !Array.isArray(user?.a)) {
      user.a = [user.a];
    }

    if (user?.d !== undefined && !Array.isArray(user?.d)) {
      user.d = [user.d];
    }

    if (user?.dd !== undefined && !Array.isArray(user?.dd)) {
      user.dd = [user.dd];
    }

    if (user?.fd !== undefined && !Array.isArray(user?.fd)) {
      user.fd = [user.fd];
    }

    if (user?.ls !== undefined && !Array.isArray(user?.ls)) {
      user.ls = [user.ls];
    } else if (user?.ls === undefined) {
      user.ls = [LanguageType.English];
    }

    return {
      ...user,
      token,
      expired: user.exp < Date.now() / 1000,
      checkAction: (actionType) => user.a?.some((x) => x === actionType),
      checkUserRole: (userRoleType, userRoleActionType) => {
        let countryAction = ActionTypes.find((x) => x?.data?.userRole === userRoleType && x?.data?.userRoleActionType === userRoleActionType);
        return user.a?.some((x) => x === countryAction?.value);
      },
      anyUserRoleAction: (userRoleActionType) => {
        let countryAction = ActionTypes.filter((x) => x?.data?.userRoleActionType === userRoleActionType);

        return user.a?.some((x) => countryAction.some((c) => x === c?.value));
      },
      getUserRoles: () => {
        let userRoleActions = ActionTypes.filter((a) => user.a?.some((x) => a.value === x) && a.data?.userRole !== undefined).map((x) => x.data.userRole);

        return [...new Set(userRoleActions)];
      },
    };
  };

  return [getValue(), setToken];
}
