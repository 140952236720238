import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTruck } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router';
import { format } from 'react-string-format';
import { Button, Drawer, Element, Input, Page, Select, Table } from '../../components/globals';
import DeliveryItemDetail from '../../components/pages/delivery/DeliveryItemDetail';
import DeliveryReportHeader from '../../components/pages/delivery/DeliveryReportHeader';
import DeliveryUser from '../../components/pages/delivery/DeliveryUser';
import {
  ActionType,
  CountryTypes,
  DeliveryColumns,
  DeliveryStatusType,
  DeliveryStatusTypes,
  DrugTypes,
  RoleColumnDomainType,
  UserRoleActionType,
  UserRoleType,
} from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { deliveryList, deliveryReject, deliveryReport, roleColumnCache, userList } from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function Deliveries() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const [user] = useCacheUser();
  const [formRef] = Form.useForm();
  const params = new URLSearchParams(search);

  const isAllCountry = user.checkAction(ActionType.DeliveryAllCountry);
  const isAll = user.checkAction(ActionType.DeliveryAll);

  const [response, setResponse] = useState(null);
  const [reports, setReports] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [users, setUsers] = useState([]);
  const [roleColumns, setRoleColumns] = useState([]);

  const [referenceSearchValue, setReferenceSearchValue] = useState('');

  const [userPanelVisibility, setUserPanelVisibility] = useState(false);
  const [rejectPanelVisibility, setRejectPanelVisibility] = useState(false);
  const [createPanelVisibility, setCreatePanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
    reject: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    includeUser: true,
    includeTargetUser: true,
    includeFlow: true,
    includeCurrentStep: true,
    includeTreatments: true,
    targetUserId: isAll ? null : user.i,
    reference: params.get('reference'),
    status: undefined,
  });

  const [reportFilter] = useState({
    pageNumber: 1,
    dataLimit: 999,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    targetUserId: isAll ? null : user.i,
  });

  const [userFilter] = useState({
    pageNumber: 1,
    dataLimit: 9999,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: user?.c,
    userRole: UserRoleType.Patient,
  });

  const [roleColumnFilter] = useState({
    orderBy: ['row|asc'],
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    country: parseInt(user.c),
    roleId: user.r,
    domain: RoleColumnDomainType.Delivery,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    if (!user?.dd) {
      notifyError(t('RoleDeliveryDrugNotFoundException'));
      navigate(`/`);
      return;
    }

    let [roleColumnResponse, deliveryListResponse, deliveryReportResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        roleColumnCache(roleColumnFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        deliveryList(filter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        deliveryReport({ ...reportFilter, country: filter.country }, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    if (roleColumnResponse?.data) {
      setRoleColumns(roleColumnResponse.data);
    }

    if (deliveryListResponse) {
      deliveryListResponse.data = deliveryListResponse.data.map((x) => {
        x.drugData = DrugTypes.find((f) => f.value === x.drug);
        x.statusData = DeliveryStatusTypes.find((f) => f.value === x.status);
        x.className = x.status === DeliveryStatusType.Pending && x.currentStep.userRole === user.u ? 'naples-yellow-item' : null;

        return x;
      });

      setResponse(deliveryListResponse);
    }

    let reportResult = [];
    if (deliveryReportResponse?.data) {
      reportResult = [
        {
          value: undefined,
          displayName: t('All'),
          count: deliveryReportResponse.data.reduce((x, y) => x + y.count, 0),
        },
        ...DeliveryStatusTypes.map((x) => ({ ...x, count: deliveryReportResponse.data.find((f) => f.status === x.value)?.count ?? 0 })),
      ];
    } else {
      reportResult = [
        {
          value: undefined,
          displayName: t('All'),
          count: 0,
        },
        ...DeliveryStatusTypes.map((x) => ({ ...x, count: 0 })),
      ];
    }

    setReports(reportResult);
    setPageState((x) => ({ ...x, list: true }));
  };

  // Events
  const onCreateClick = async () => {
    await userList({ ...userFilter, country: filter.country }, (status, res) => {
      if (status) {
        setUsers(res.data);
        setCreatePanelVisibility(true);
      }
    });
  };

  const onDeliveryUserClick = async (row) => {
    setRowData(row);
    setUserPanelVisibility(true);
  };

  const onRejectDelivery = async (row) => {
    setRowData(row);
    setRejectPanelVisibility(true);
  };

  const onRejectFinish = async (model) => {
    setPageState((x) => ({ ...x, reject: false }));

    model = { ...rowData, ...model, country: parseInt(user.c) };

    await deliveryReject(model, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('DeliveryRejected'));
        setRejectPanelVisibility(false);
      }
      setPageState((x) => ({ ...x, reject: true }));
    });
  };

  const onCreateFinish = (model) => {
    setPageState((x) => ({ ...x, create: false }));
    navigate(`/delivery/create/${UserRoleType.Patient}/${filter.country}/${model.targetUserId}`);
  };

  const onReportClick = (value) => {
    setFilter((x) => ({ ...x, status: value }));
  };

  // Components
  const expandedRowRender = (row) => {
    return <DeliveryItemDetail user={user} country={filter.country} delivery={row} list={list} />;
  };

  // Render
  return (
    <Page
      title={t('Deliveries')}
      icon={<FaTruck />}
      subTitle={
        user.checkAction(ActionType.DeliveryCreate) && (
          <Button onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Deliveries'),
          breadcrumbName: t('Deliveries'),
          icon: <FaTruck />,
        },
      ]}
      extra={[
        <span key="filter-text">{t('Filter')}</span>,
        <Input.Search
          key="reference-search"
          placeholder={t('Reference')}
          onChange={(event) => setReferenceSearchValue(event.target.value)}
          onSearch={(reference) => setFilter((x) => ({ ...x, reference }))}
          templates={['filter', referenceSearchValue !== '' && 'value']}
          defaultValue={filter.reference}
          allowClear={true}
        />,
        isAllCountry && (
          <Select
            key="country-filter"
            allowClear={false}
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            templates={['filter']}
          />
        ),
        <Select
          key="status-filter"
          data={DeliveryStatusTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={(status) => setFilter((x) => ({ ...x, status }))}
          placeholder={t('SelectStatus')}
          value={filter.status}
          templates={['filter']}
        />,
      ]}
    >
      <DeliveryReportHeader reports={reports} onClick={onReportClick} />

      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        expandable={true}
        expandedRowRender={expandedRowRender}
        columns={DeliveryColumns({
          roleColumns,
          user,
          filter,
          setFilter,
          onDeliveryUserClick:
            (filter.status === DeliveryStatusType.Pending || filter.status === undefined) &&
            user.anyUserRoleAction(UserRoleActionType.List) &&
            user.checkAction(ActionType.DeliveryUser) &&
            onDeliveryUserClick,
          onRejectDelivery:
            (filter.status === DeliveryStatusType.Pending || filter.status === undefined) && user.checkAction(ActionType.DeliveryReject) && onRejectDelivery,
        })}
      />

      {rowData && <DeliveryUser visible={userPanelVisibility} onClose={() => setUserPanelVisibility(false)} delivery={rowData} />}

      <Drawer visible={rejectPanelVisibility} onClose={() => setRejectPanelVisibility(false)}>
        <Element
          key="reject"
          onFinish={onRejectFinish}
          ready={pageState.reject}
          formRef={formRef}
          inputs={[
            {
              type: 'textarea',
              name: 'rejectReason',
              label: t('RejectReason'),
              placeholder: t('RejectReasonEmpty'),
              rules: [
                { required: false, message: t('RejectReasonEmpty') },
                { min: 3, max: 500, message: format(t('LengthValid'), 3, 500) },
              ],
            },
          ]}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>

      <Drawer visible={createPanelVisibility} onClose={() => setCreatePanelVisibility(false)}>
        <Element
          key="users"
          onFinish={onCreateFinish}
          ready={pageState.create}
          inputs={[
            {
              type: 'select',
              name: 'targetUserId',
              label: t('RolePatient'),
              placeholder: t('SelectRolePatient'),
              rules: [{ required: true, message: t('SelectRolePatientEmpty') }],
              data: users.map((x) => ({ value: x.id, text: x.fullName })),
            },
          ]}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>
    </Page>
  );
}
