const ContentTypes = [
  {
    value: 0,
    name: 'Survey',
    displayName: 'ContentTypeSurvey',
    group: 'Parent',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Static',
    displayName: 'ContentTypeStatic',
    group: 'Parent',
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'FAQ',
    displayName: 'ContentTypeFAQ',
    group: 'Parent',
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'Article',
    displayName: 'ContentTypeArticle',
    group: 'Parent',
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  },
  {
    value: 4,
    name: 'Course',
    displayName: 'ContentTypeCourse',
    group: 'Parent',
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  },
  {
    value: 5,
    name: 'Question',
    displayName: 'ContentTypeQuestion',
    group: 'Child',
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {}
  },
  {
    value: 6,
    name: 'Answer',
    displayName: 'ContentTypeAnswer',
    group: 'Inner',
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {}
  },
  {
    value: 7,
    name: 'Video',
    displayName: 'ContentTypeVideo',
    group: 'Parent',
    shortName: null,
    description: null,
    prompt: null,
    order: 800,
    data: {}
  },
  {
    value: 8,
    name: 'Podcast',
    displayName: 'ContentTypePodcast',
    group: 'Parent',
    shortName: null,
    description: null,
    prompt: null,
    order: 1000,
    data: {}
  },
  {
    value: 9,
    name: 'Document',
    displayName: 'ContentTypeDocument',
    group: 'Parent',
    shortName: null,
    description: null,
    prompt: null,
    order: 1100,
    data: {}
  }
];

export default ContentTypes;