import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { reportList } from '../../../services/api';
import { Card, ComposedChart, PieChart } from '../../globals';

export default function ManagerDashboard() {
  // Definitions
  const { t } = useTranslation();
  const [pageState, setPageState] = useState({ list: false });
  const [data, setData] = useState(null);

  // Hooks
  useEffect(() => {
    list();
  }, []);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await reportList((status, res) => {
      if (status) {
        if (res.data?.patientGenders?.Male || res.data?.patientGenders?.Female) {
          res.data.patientGenders = [
            { name: t('Male'), value: res.data.patientGenders.Male ?? 0, color: '#00d0e3' },
            { name: t('Female'), value: res.data.patientGenders.Female ?? 0, color: '#6353a8' },
          ];
        }

        setData(res.data);
      }

      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Render
  return (
    <Container>
      <Card
        ready={pageState?.list}
        title={t('PatientGenders')}
        information={t('PatientGenderInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && <Body>{data.patientGenders?.length > 0 ? <PieChart data={data.patientGenders} /> : <Point>0</Point>}</Body>}
      </Card>
      <Card
        ready={pageState?.list}
        title={t('NumberOfActiveHCPs')}
        information={t('NumberOfActiveHCPsInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.activeHCPCount}</Point>
          </Body>
        )}
      </Card>
      <Card
        ready={pageState?.list}
        title={t('NumberOfActiveHCPsGraph')}
        information={t('NumberOfActiveHCPsGraphInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <ComposedChart data={data} actives={true} />
          </Body>
        )}
      </Card>
      <Card
        ready={pageState?.list}
        title={t('NumberOfPatients')}
        information={t('NumberOfPatientsInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.patientCount}</Point>
          </Body>
        )}
      </Card>
      <Card
        ready={pageState?.list}
        title={t('NumberOfHCPs')}
        information={t('NumberOfHCPsInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.hcpCount}</Point>
          </Body>
        )}
      </Card>
      <Card
        ready={pageState?.list}
        title={t('NumberOfHCPsGraph')}
        information={t('NumberOfHCPsGraphInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <ComposedChart data={data} actives={false} />
          </Body>
        )}
      </Card>
      <Card
        ready={pageState?.list}
        title={t('NumberOfActivePatients')}
        information={t('NumberOfActivePatientsInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.activePatientCount}</Point>
          </Body>
        )}
      </Card>
      <Card
        ready={pageState?.list}
        title={t('AveragePatientAge')}
        information={t('AveragePatientAgeInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.averagePatientAge?.toFixed(1)}</Point>
          </Body>
        )}
      </Card>
      <Card
        ready={pageState?.list}
        title={t('AveragePatientPerHCP')}
        information={t('AveragePatientPerHCPInformation')}
        templates={['widget', 'manager-widget', 'with-header', 'header-information', 'header-colored']}
      >
        {data && (
          <Body>
            <Point>{data?.averagePatientHCPRate?.toFixed(1)}</Point>
          </Body>
        )}
      </Card>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  > * {
    float: left;
    &:not(:nth-child(3n)) {
      margin-right: 24px;
    }

    @media only screen and (max-width: 1600px) {
      &:not(:nth-child(3n)) {
        margin-right: auto;
      }

      &:not(:nth-child(2n)) {
        margin-right: 16px;
      }
    }

    @media only screen and (max-width: 1200px) {
      &:not(:nth-child(3n)) {
        margin-right: auto;
      }

      &:not(:nth-child(2n)) {
        margin-right: auto;
      }
    }
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: calc(400px - 48px);
  width: 100%;
`;

const Point = styled.div`
  display: block;
  font-weight: bold;
  font-size: 180px;
  color: #00d0e3;
`;
