const UserDeactiveReasonTypes = [
  {
    value: 0,
    name: 'PatientPrefferedToLeave',
    displayName: 'PatientPrefferedToLeave',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'TreatmentOnHold',
    displayName: 'TreatmentOnHold',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'DifferentTreatmentSelected',
    displayName: 'DifferentTreatmentSelected',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'PatientDeath',
    displayName: 'PatientDeath',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  }
];

export default UserDeactiveReasonTypes;