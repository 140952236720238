import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHandHoldingMedical, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { Button, Drawer, Element, Extra, Page, Select, Table } from '../../components/globals';
import { ActionType, CountryTypes, RoleColumnDomainType, TreatmentColumns, UserRoleActionType, UserRoleType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { roleColumnCache, treatmentDelete, treatmentList, treatmentSuspend, userList } from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function Treatments() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);
  const [users, setUsers] = useState([]);
  const [relateds, setRelateds] = useState([]);
  const [createPanelVisibility, setCreatePanelVisibility] = useState(false);
  const [relatedPanelVisibility, setRelatedPanelVisibility] = useState(false);
  const [pageState, setPageState] = useState({ list: true });
  const [roleColumns, setRoleColumns] = useState([]);

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: user.u === UserRoleType.Patient ? user.i : null,
    includeUser: user.u !== UserRoleType.Patient,
    includeDelivery: true,
  });

  const [relatedFilter] = useState({
    pageNumber: 1,
    dataLimit: 100,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    includeUser: user.u !== UserRoleType.Patient,
    includeDelivery: true,
  });

  const [roleColumnFilter] = useState({
    orderBy: ['row|asc'],
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    country: parseInt(user.c),
    roleId: user.r,
    domain: RoleColumnDomainType.Treatment,
  });

  const [userFilter] = useState({
    pageNumber: 1,
    dataLimit: 9999,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: user.i,
    userRole: UserRoleType.Patient,
  });

  const isAllCountry = user.checkAction(ActionType.TreatmentAllCountry);

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let [roleColumnResponse, listResponse, userListResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        roleColumnCache(roleColumnFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        treatmentList(filter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        if (user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List)) {
          userList(userFilter, (status, res) => {
            resolve(status ? res : null);
          });
        } else {
          resolve(null);
        }
      }),
    ]);

    if (listResponse) {
      setResponse(listResponse);
      setPageState((x) => ({ ...x, list: true }));
    }

    if (roleColumnResponse?.data) {
      setRoleColumns(roleColumnResponse.data);
    }

    if (userListResponse?.data) {
      setUsers(userListResponse.data);
    }
  };

  const getTemplates = () => {
    if (isAllCountry) return 'default-1-filter-2';

    return 'default-0-filter-2';
  };

  // Events
  const onCreateClick = async () => {
    if (user.u === UserRoleType.Patient) {
      navigate(`/treatment/create`);
      return;
    }

    if (!user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List)) {
      notifyError(t('UserForbiddenException'));
      return;
    }

    setCreatePanelVisibility(true);
  };

  const onCreateFinish = (model) => {
    setPageState((x) => ({ ...x, create: false }));
    navigate(`/treatment/create/${filter.country}/${model.userId}`);
  };

  const onUpdateClick = (row) => {
    if (user.u === UserRoleType.Patient) {
      navigate(`/treatment/update/${row.id}`);
    } else {
      if (!user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List)) {
        notifyError(t('UserForbiddenException'));
        return;
      }

      navigate(`/treatment/update/${filter.country}/${row.userId}/${row.id}`);
    }
  };

  const onSuspendClick = async (row) => {
    let model = { ...row, userId: user.i, userRole: row.user ? row.user.userRole : user.u, country: row.user ? row.user.country : user.c };
    await treatmentSuspend(model, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('UpdateCompleteSuccess'));
      }
    });
  };

  const onDeleteClick = async (row) => {
    setPageState((x) => ({ ...x, delete: false }));

    await treatmentDelete(
      { id: row.id, userId: row.user ? row.user.id : user.i, userRole: row.user ? row.user.userRole : user.u, country: row.user ? row.user.country : user.c },
      (status, res) => {
        if (status) {
          list();
          notifySuccess(t('DeleteCompleteSuccess'));
        }

        setPageState((x) => ({ ...x, delete: true }));
      }
    );
  };

  const onRelatedClick = async (row) => {
    await treatmentList({ ...relatedFilter, deliveryId: row.deliveryId }, (status, res) => {
      setRelateds(res);
      setRelatedPanelVisibility(true);
    });
  };

  // Render
  return (
    <Page
      title={t('Treatments')}
      icon={<FaHandHoldingMedical />}
      subTitle={
        user.checkAction(ActionType.TreatmentCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Treatments'),
          breadcrumbName: t('Treatments'),
          icon: <FaHandHoldingMedical />,
        },
      ]}
      templates={getTemplates()}
      extra={[
        user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List) && (
          <Select
            key="user-filter"
            data={users.map((x) => ({ value: x.id, text: x.fullName }))}
            onChange={(userId) => setFilter((x) => ({ ...x, userId }))}
            placeholder={t('SelectUser')}
            templates={['filter']}
            defaultValue={filter.userId}
            value={filter.userId}
          />
        ),
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            value={filter.country}
            templates={['filter']}
            allowClear={false}
          />
        ),
        <Extra key="filter" pageState={pageState} filter={filter} setFilter={setFilter} />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={TreatmentColumns({
          roleColumns,
          filter,
          setFilter,
          onRelatedClick,
          onUpdateClick: user.checkAction(ActionType.TreatmentUpdate) && onUpdateClick,
          onSuspendClick: user.checkAction(ActionType.TreatmentUpdate) && onSuspendClick,
          onDeleteClick: user.checkAction(ActionType.TreatmentDelete) && onDeleteClick,
          isDelete: filter.isDeleted,
        })}
      />

      <Drawer visible={createPanelVisibility} onClose={() => setCreatePanelVisibility(false)}>
        <Element
          key="users"
          onFinish={onCreateFinish}
          ready={pageState.create}
          inputs={[
            {
              type: 'select',
              name: 'userId',
              label: t('User'),
              placeholder: t('SelectUser'),
              rules: [{ required: true, message: t('UserEmpty') }],
              data: users.map((x) => ({ value: x.id, text: x.fullName })),
            },
          ]}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>

      <Drawer title={t('TreatmentRelateds')} width="80%" visible={relatedPanelVisibility} onClose={() => setRelatedPanelVisibility(false)}>
        <Table
          ready={pageState.list}
          response={relateds}
          columns={TreatmentColumns({
            roleColumns,
          })}
        />
      </Drawer>
    </Page>
  );
}
