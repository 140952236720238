const RoleFieldGroupType = {
  Select: 0,
  MultiSelect: 1,
  Date: 2,
  DateTime: 3,
  Number: 4,
  Input: 5,
  Phone: 6,
  Double: 7,
  Email: 8
};

export default RoleFieldGroupType;