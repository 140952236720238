import { Layout } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import { ContentType, CountryType } from '../../../constants';
import { contentStaticDetail, contentStaticList } from '../../../services/api';
import { getLocale } from '../../../services/cache';
import { getLanguageType } from '../../../utilies/localization';
import { ReduceContext } from '../../contexts/ReduceContext';
import { Modal } from '../../globals';

const { Footer: AntFooter } = Layout;

export default function FooterBase({ user, collapsed }) {
  // Definitions
  const location = useLocation();
  const navigate = useNavigate();
  const [response, setResponse] = useState(null);
  const [detail, setDetail] = useState(null);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [secondPanelVisibility, setSecondPanelVisibility] = useState(false);
  const [state, dispatch] = useContext(ReduceContext);
  const [hashDetail, setHashDetail] = useState(null);

  const [filter] = useState({
    pageNumber: 1,
    dataLimit: 3,
    country: user.c,
    userCountry: user.c,
    type: ContentType.Static,
    justParent: true,
  });

  // Hooks
  useEffect(() => {
    if (state?.language?.change) {
      load();
      dispatch({ ...state, language: { ...state.language, change: false } });
    }
  }, [state]);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (location.hash) {
      hashContent(location.hash);
    }
  }, [location]);

  const hashContent = async (hash) => {
    await contentStaticDetail({ type: ContentType.Static, id: hash.slice(1), language: getLanguageType(getLocale()) }, (status, res) => {
      setPanelVisibility(false);
      setSecondPanelVisibility(true);
      setHashDetail(res.data);
    });
  };

  // Functions
  const load = async () => {
    await contentStaticList({ ...filter, language: getLanguageType(getLocale()) }, (status, res) => {
      if (status) {
        setResponse(res);
      }
    });
  };

  // Events
  const onContentClick = async (content) => {
    await contentStaticDetail({ ...filter, id: content.id, group: content.contentGroup }, (status, res) => {
      if (status) {
        setDetail(res);
        setPanelVisibility(true);
      }
    });
  };

  const onCloseSecondPanel = async () => {
    setPanelVisibility(true);
    setSecondPanelVisibility(false);
    navigate(location.pathname);
  };

  // Components
  const RightContainer = () => {
    switch (user?.c) {
      case CountryType.Russian:
        return <Right>M-RU-00010166, январь 2023. Coral ©{new Date().getFullYear()}</Right>;

      default:
        return <Right>Coral ©{new Date().getFullYear()}</Right>;
    }
  };

  return (
    <Container collapsed={collapsed}>
      <RightContainer />

      {response?.data && (
        <Left collapsed={collapsed}>
          {response.data.map((x, i) => (
            <Content key={`content-${i}`} onClick={() => onContentClick(x)}>
              {x.title}
            </Content>
          ))}
        </Left>
      )}

      <Modal visible={panelVisibility} onCancelClick={() => setPanelVisibility(false)} footer={null} templates={['document-preview']}>
        {detail?.data && <Body dangerouslySetInnerHTML={{ __html: detail.data.body }} />}
      </Modal>

      <Modal visible={secondPanelVisibility} onCancelClick={() => onCloseSecondPanel(false)} footer={null} templates={['document-preview']}>
        {hashDetail && <Body dangerouslySetInnerHTML={{ __html: hashDetail.body }} />}
      </Modal>
    </Container>
  );
}

const Container = styled(AntFooter)`
  padding: 0px 12px 0px 322px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  transition: padding ease-in-out 0.3s;
  color: ${(x) => x.theme.colors.antiFlashWhiteDark};
  background-color: ${(x) => x.theme.colors.deepKaomaru};

  ${({ collapsed }) =>
    collapsed === 'true' &&
    css`
      padding: 0px 12px 0px 72px;
    `}

  @media only screen and (max-width: 800px) {
    height: auto;
    flex-direction: column-reverse;
  }
`;

const Right = styled.div`
  font-weight: bold;
`;

const Left = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 800px) {
    flex-direction: column;

    ${({ collapsed }) =>
      collapsed !== 'true' &&
      css`
        display: none;
      `}
  }
`;

const Content = styled.div`
  font-weight: bold;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    color: ${(x) => x.theme.colors.darkTurquoise};
  }
`;

const Body = styled.div`
  height: 100%;
  overflow-y: scroll;
`;
