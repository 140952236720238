const TreatmentLastStatusTypes = [
  {
    value: 0,
    name: 'DSInjection2LessThenWeeks8',
    displayName: 'DSInjection2LessThenWeeks8',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {
      stage: 1
    }
  },
  {
    value: 1,
    name: 'DSInjection3LessThenWeeks8',
    displayName: 'DSInjection3LessThenWeeks8',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      stage: 1
    }
  },
  {
    value: 2,
    name: 'DSInjectionMaintenanceRegimenLessThenWeeks8',
    displayName: 'DSInjectionMaintenanceRegimenLessThenWeeks8',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      stage: 1
    }
  },
  {
    value: 3,
    name: 'DSInjectionFrom8to12Weeks',
    displayName: 'DSInjectionFrom8to12Weeks',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      stage: 1
    }
  },
  {
    value: 4,
    name: 'DSInjectionGreaterThan12Weeks',
    displayName: 'DSInjectionGreaterThan12Weeks',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      stage: 1
    }
  },
  {
    value: 5,
    name: 'INCInjectionLessThan12Weeks',
    displayName: 'INCInjectionLessThan12Weeks',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      stage: 2
    }
  },
  {
    value: 6,
    name: 'INCInjectionGreaterThan12Weeks',
    displayName: 'INCInjectionGreaterThan12Weeks',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {
      stage: 2
    }
  }
];

export default TreatmentLastStatusTypes;