const RoleFieldGroupTypes = [
  {
    value: 0,
    name: 'Select',
    displayName: 'Select',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 1,
    name: 'MultiSelect',
    displayName: 'MultiSelect',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 2,
    name: 'Date',
    displayName: 'Date',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 3,
    name: 'DateTime',
    displayName: 'DateTime',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 4,
    name: 'Number',
    displayName: 'Number',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 5,
    name: 'Input',
    displayName: 'Input',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 6,
    name: 'Phone',
    displayName: 'Phone',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 7,
    name: 'Double',
    displayName: 'Double',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 8,
    name: 'Email',
    displayName: 'Email',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  }
];

export default RoleFieldGroupTypes;