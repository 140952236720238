import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaUserAlt, FaUserEdit, FaUserMd, FaUserNurse, FaUserShield, FaUserTag, FaUserTie } from 'react-icons/fa';
import { RiAdminFill, RiEdit2Fill, RiUserHeartFill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Card, Page, Radio } from '../../components/globals';
import UserField from '../../components/pages/user/UserField';
import UserParent from '../../components/pages/user/UserParent';
import { CountryType, CountryTypes, UserRoleActionType, UserRoleType, UserRoleTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { roleParentListGlobal, userCreate, userDetail, userRelationList, userUpdate } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import { getUserFieldFormData } from '../../utilies/userField';

export default function UserCreateUpdate() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, userRoleType, countryType } = useParams();
  const [searchParams] = useSearchParams();

  const [user] = useCacheUser();
  const [formRef] = Form.useForm();

  const isCreate = id === undefined;
  const userRole = UserRoleTypes.find((x) => x.value === parseInt(userRoleType));
  const country = CountryTypes.find((x) => x.value === parseInt(countryType ?? user.c));

  const [userParents, setUserParents] = useState([]);
  const [roleParents, setRoleParents] = useState(null);

  const [activeTab, setActiveTab] = useState('detail');
  const [pageState, setPageState] = useState({ form: true });

  const [filter, setFilter] = useState({
    id,
    includeFields: true,
    includeParents: true,
    country: country.value,
    userRole: userRole.value,
  });

  const [relationIdFilter] = useState({
    includeUser: true,
    relationUserId: id,
    relationUserRole: userRole.value,
    currentUserRole: userRole.value,
    country: country.value,
    isDeleted: false,
  });

  const isAllCountry = user.checkUserRole(userRole.value, UserRoleActionType.AllCountry);

  // Hooks
  useEffect(() => {
    if (
      (isCreate && !user.checkUserRole(userRole.value, UserRoleActionType.Create)) ||
      (!isCreate && !user.checkUserRole(userRole.value, UserRoleActionType.Update)) ||
      (!isAllCountry && user.c !== country.value)
    ) {
      setTimeout(() => navigate(`/`, { error: 'UserForbiddenException' }), 500);
      return;
    }

    load();
  }, [id]);

  // Functions
  const load = async () => {
    setPageState((x) => ({ ...x, form: false }));

    let [roleParentResponse, userDetailResponse, userRelationResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        roleParentListGlobal(filter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        if (filter.id) {
          userDetail(filter, (status, res) => {
            resolve(status ? res : null);
          });
        } else {
          resolve(null);
        }
      }),
      new Promise((resolve, reject) => {
        if (relationIdFilter.relationUserId && user.checkUserRole(userRole.value, UserRoleActionType.Parent)) {
          userRelationList(relationIdFilter, (status, res) => {
            resolve(status ? res : null);
          });
        } else {
          resolve(null);
        }
      }),
    ]);

    if (filter.id) {
      if (userDetailResponse === null) {
        setTimeout(() => navigate(`/`, { error: 'UserForbiddenException' }), 500);
        return;
      }

      userDetailResponse.data.fields = getUserFieldFormData(userDetailResponse.data.fields, userDetailResponse.data.timeOffset);
      formRef.setFieldsValue(userDetailResponse.data);
    }

    if (roleParentResponse !== null && roleParentResponse.data.length > 0) {
      roleParentResponse.data = roleParentResponse.data.map((x) => ({ ...x, userRoleData: UserRoleTypes.find((f) => f.value === x.userRole) }));

      setRoleParents(roleParentResponse.data);
    }

    if (userRelationResponse !== null) {
      setUserParents(
        userRelationResponse.data.map((x) => {
          x.user.isRelated = true;

          return x.user;
        })
      );
    }

    setPageState((x) => ({ ...x, form: true }));

    relationDetail();
  };

  const relationDetail = async () => {
    if (user.c === CountryType.Russian && searchParams) {
      if (
        !user.checkUserRole(UserRoleType.Doctor, UserRoleActionType.List) ||
        (!user.checkUserRole(UserRoleType.Doctor, UserRoleActionType.AllCountry) && user.c !== country.value)
      ) {
        return;
      }

      let relationId = searchParams.get('r');
      if (relationId) {
        await userDetail({ id: relationId, includeFields: true, country: country.value, userRole: UserRoleType.Doctor }, (status, res) => {
          if (status) {
            setUserParents((x) => [...x, { ...res.data, isRelated: true }]);
          }
        });
      }
    }
  };

  // Events
  const onUserFormFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    let request = isCreate ? userCreate : userUpdate;

    model = { ...model, userParents: userParents.map((x) => x.id) };

    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t(isCreate ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        setFilter((x) => ({ ...x, id: res.data }));

        let url = `/user/update/${userRole.value}/${country.value}/${res.data}`;
        if (searchParams) {
          url += `?${searchParams?.toString()}`;
        }

        setActiveTab('detail');
        setTimeout(() => navigate(url), 500);
      }
      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Components
  const getRoleIcon = (role) => {
    switch (role) {
      case UserRoleType.Owner:
      case UserRoleType.Administrator:
      case UserRoleType.Manager:
        return <RiAdminFill />;
      case UserRoleType.Editor:
        return <FaUserEdit />;
      case UserRoleType.PSP:
        return <FaUserTie />;
      case UserRoleType.Doctor:
        return <FaUserMd />;
      case UserRoleType.Nurse:
        return <FaUserNurse />;
      case UserRoleType.Pharmacist:
        return <FaUserTag />;
      case UserRoleType.Warehouse:
      case UserRoleType.IMS:
        return <FaUserShield />;
      case UserRoleType.Patient:
        return <RiUserHeartFill />;

      default:
        return <FaUserAlt />;
    }
  };

  // Render
  return (
    <Page
      title={t(isCreate ? 'AddNew' : 'Update')}
      icon={isCreate ? <FaPlus /> : <RiEdit2Fill />}
      routes={[
        {
          path: `/user/list/${userRole.value}`,
          name: t(`Menu${userRole.displayName}`),
          breadcrumbName: 'UserCreateUpdate',
          icon: getRoleIcon(userRole.value),
        },
        {
          name: t(isCreate ? 'AddNew' : 'Update'),
          breadcrumbName: 'CreateUpdate',
          icon: isCreate ? <FaPlus /> : <RiEdit2Fill />,
        },
      ]}
      extra={[
        (isCreate || (!isCreate && activeTab === 'detail')) && (
          <Button key="confirm" onClick={() => formRef.submit()} templates={['colored']}>
            {t('Confirm')}
          </Button>
        ),
      ]}
    >
      <Card ready={pageState.form} templates={['page']}>
        {roleParents != null && user.checkUserRole(userRole.value, UserRoleActionType.Parent) && (
          <Radio.Group
            options={[
              { label: t('Detail'), value: 'detail' },
              { label: t('UserParents'), value: 'parents' },
            ]}
            onChange={(e) => setActiveTab(e.target.value)}
            value={activeTab}
            templates={['full-width', 'mb-24']}
            optionType="button"
          />
        )}

        <TabPage visible={activeTab === 'detail'}>
          <UserField user={user} searchParams={searchParams} userRoleType={userRole.value} countryType={country.value} formRef={formRef} onFinish={onUserFormFinish} />
        </TabPage>

        <TabPage visible={activeTab === 'parents'}>
          {roleParents && user.checkUserRole(userRole.value, UserRoleActionType.Parent) && (
            <UserParent
              isCreate={isCreate}
              userId={filter.id}
              userRole={userRole.value}
              country={country.value}
              userParents={userParents}
              setUserParents={setUserParents}
            />
          )}
        </TabPage>
      </Card>
    </Page>
  );
}

const TabPage = styled.div`
  ${({ visible }) =>
    visible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;
