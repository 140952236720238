const TreatmentLastStatusType = {
  DSInjection2LessThenWeeks8: 0,
  DSInjection3LessThenWeeks8: 1,
  DSInjectionMaintenanceRegimenLessThenWeeks8: 2,
  DSInjectionFrom8to12Weeks: 3,
  DSInjectionGreaterThan12Weeks: 4,
  INCInjectionLessThan12Weeks: 5,
  INCInjectionGreaterThan12Weeks: 6
};

export default TreatmentLastStatusType;