const RoleColumnType = {
  Reference: 0,
  Drug: 1,
  DailyDoseMg: 2,
  DailyDoseMl: 3,
  DrugAmount: 4,
  TotalBottle: 5,
  PreparedBottle: 6,
  UserFullName: 7,
  UserReference: 8,
  StartDate: 9,
  EndDate: 10,
  Duration: 11,
  BatchNumber: 12,
  Status: 13,
  DeliveryFlow: 14,
  CurrentStep: 15,
  Sender: 16,
  Recipient: 17,
  DeliveredDate: 18,
  CreationTime: 19,
  ModificationTime: 20,
  ExternalId: 21,
  DeliveryReference: 22
};

export default RoleColumnType;