import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHospitalUser, FaPlus } from 'react-icons/fa';
import { Button, Drawer, Element, Extra, Page, Select, Table } from '../../components/globals';
import { ActionType, CountryTypes, UserRoleActionType, UserRoleType } from '../../constants';
import VisitColumns from '../../constants/columns/VisitColumns';
import VisitInputs from '../../constants/inputs/VisitInputs';
import { useCacheUser } from '../../hooks/useCacheUser';
import { userList, visitCreate, visitDelete, visitList, visitUpdate } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';

export default function Visits() {
  // Definitions
  const { t } = useTranslation();
  const [visitFormRef] = Form.useForm();
  const [user] = useCacheUser();

  const [users, setUsers] = useState([]);
  const [response, setResponse] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [panelVisiblity, setPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: user.u === UserRoleType.Patient ? user.i : null,
    userRole: UserRoleType.Patient,
    includeUser: user.u !== UserRoleType.Patient,
  });

  const [userFilter] = useState({
    pageNumber: 1,
    dataLimit: 9999,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: user.i,
    userRole: UserRoleType.Patient,
  });

  const isAllCountry = user.checkAction(ActionType.VisitAllCountry);

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await visitList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  const getTemplates = () => {
    if (isAllCountry) return 'default-1-filter-2';

    return 'default-0-filter-2';
  };

  // Events
  const onCreateClick = async () => {
    if (user.u !== UserRoleType.Patient) {
      await userList(userFilter, (status, res) => {
        if (status) {
          setUsers(res.data);
        }
      });
    }

    setIsCreate(true);
    setPanelVisibility(true);
    visitFormRef.resetFields();
  };

  const onUpdateClick = (row) => {
    row.date = moment(row.date);

    setIsCreate(false);
    setPanelVisibility(true);
    visitFormRef.setFieldsValue(row);
  };

  const onDeleteClick = async (row) => {
    setPageState((x) => ({ ...x, delete: false }));

    await visitDelete({ id: row.id, userId: row.userId, country: filter.country, userRole: filter.userRole }, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('DeleteCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onVisitFormFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    let request = isCreate ? visitCreate : visitUpdate;

    model = { ...model, country: filter.country, userRole: filter.userRole };

    await request(model, (status) => {
      if (status) {
        list();
        setPanelVisibility(false);
        notifySuccess(t(isCreate ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
      }
      setPageState((x) => ({ ...x, form: true }));
    });
  };

  return (
    <Page
      title={t('Visits')}
      icon={<FaHospitalUser />}
      subTitle={
        user.checkAction(ActionType.VisitCreate) &&
        (user.u === UserRoleType.Patient || user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List)) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Visits'),
          breadcrumbName: t('Visits'),
          icon: <FaHospitalUser />,
        },
      ]}
      templates={getTemplates()}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            value={filter.country}
            templates={['filter']}
            allowClear={false}
          />
        ),
        <Extra key="filter" pageState={pageState} filter={filter} setFilter={setFilter} />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={VisitColumns({
          isDeleted: filter.isDeleted,
          isUserVisible: filter.includeUser,
          onUpdateClick: user.checkAction(ActionType.VisitUpdate) && onUpdateClick,
          onDeleteClick: user.checkAction(ActionType.VisitDelete) && onDeleteClick,
        })}
      />

      <Drawer visible={panelVisiblity} onClose={() => setPanelVisibility(false)}>
        <Element
          key="visit-form"
          ready={pageState.form}
          formRef={visitFormRef}
          onFinish={onVisitFormFinish}
          inputs={VisitInputs({ userId: user.i, users, isCreate })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>
    </Page>
  );
}
