const NoticeContactTypes = [
  {
    value: 0,
    name: 'Email',
    displayName: 'Email',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Phone',
    displayName: 'Phone',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  }
];

export default NoticeContactTypes;