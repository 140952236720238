import { del, download, form, get, post, put, upload } from './request';

export const login = (params, callback) => get(`/login`, callback, params);
export const loginLogout = (model, callback) => post(`/login/logout`, model, callback);
export const loginEmail = (model, callback) => form(`/login/email`, model, callback);
export const loginSms = (model, callback) => form(`/login/sms`, model, callback);
export const loginSmsRequest = (model, callback) => post(`/login/sms/request`, model, callback);
export const loginCreatePasswordCheck = (params, callback) => get(`/login/create-password`, callback, params);
export const loginCreatePasswordConfirm = (model, callback) => put(`/login/create-password`, model, callback);
export const loginForgotPasswordCheck = (params, callback) => get(`/login/forgot-password`, callback, params);
export const loginForgotPasswordRequest = (model, callback) => post(`/login/forgot-password`, model, callback);
export const loginForgotPasswordConfirm = (model, callback) => put(`/login/forgot-password`, model, callback);
export const loginRelations = (callback) => get(`/login/relations`, callback);
export const loginApprove = (model, callback) => put(`/login/approve`, model, callback);
export const loginRefresh = (callback) => put(`/login/refresh`, {}, callback);

export const loginGigyaLogin = (params, callback) => get(`/login/gigya/login`, callback, params);

export const roleList = (params, callback) => get(`/role/list`, callback, params, { country: params.country });
export const roleDetail = (params, callback) => get(`/role/detail`, callback, params, { country: params.country });
export const roleUserRoles = (country, callback) => get(`/role/user-roles`, callback, null, { country });
export const roleCreate = (model, callback) => post(`/role`, model, callback, null, { country: model.country });
export const roleLogout = (model, callback) => put(`/role/logout`, model, callback, null, { country: model.country });
export const roleMultipleDeleteRestore = (model, callback) => del(`/role`, model, callback, null, { country: model.country });
export const roleDataExport = (params, callback) => download(`/role/data`, callback, params, { country: params.country });
export const roleDataImport = (country, form, callback) => upload(`/role/data`, form, callback, null, { country });

export const roleFieldDetail = (params, callback) => get(`/role/field/detail`, callback, params, { country: params.country });
export const roleFieldList = (params, callback) => get(`/role/field/list`, callback, params, { country: params.country });
export const roleFieldCache = (params, callback) => get(`/role/field/cache`, callback, params, { country: params.country });
export const roleFieldCreate = (model, callback) => post(`/role/field`, model, callback, null, { country: model.country });
export const roleFieldUpdate = (model, callback) => put(`/role/field`, model, callback, null, { country: model.country });
export const roleFieldUpdateRequirement = (model, callback) => put(`/role/field/requirement`, model, callback, null, { country: model.country });
export const roleFieldMultipleDeleteRestore = (model, callback) => del(`/role/field`, model, callback, null, { country: model.country });

export const roleInputDetail = (params, callback) => get(`/role/input/detail`, callback, params, { country: params.country });
export const roleInputList = (params, callback) => get(`/role/input/list`, callback, params, { country: params.country });
export const roleInputCache = (params, callback) => get(`/role/input/cache`, callback, params, { country: params.country });
export const roleInputCreate = (model, callback) => post(`/role/input`, model, callback, null, { country: model.country });
export const roleInputUpdate = (model, callback) => put(`/role/input`, model, callback, null, { country: model.country });
export const roleInputUpdateRequirement = (model, callback) => put(`/role/input/requirement`, model, callback, null, { country: model.country });
export const roleInputMultipleDeleteRestore = (model, callback) => del(`/role/input`, model, callback, null, { country: model.country });

export const roleColumnDetail = (params, callback) => get(`/role/column/detail`, callback, params, { country: params.country });
export const roleColumnList = (params, callback) => get(`/role/column/list`, callback, params, { country: params.country });
export const roleColumnCache = (params, callback) => get(`/role/column/cache`, callback, params, { country: params.country });
export const roleColumnCreate = (model, callback) => post(`/role/column`, model, callback, null, { country: model.country });
export const roleColumnUpdate = (model, callback) => put(`/role/column`, model, callback, null, { country: model.country });
export const roleColumnUpdateRequirement = (model, callback) => put(`/role/column/requirement`, model, callback, null, { country: model.country });
export const roleColumnMultipleDeleteRestore = (model, callback) => del(`/role/column`, model, callback, null, { country: model.country });

export const roleActionList = (params, callback) => get(`/role/action/list`, callback, params, { country: params.country });
export const roleActionUpdate = (model, callback) => put(`/role/action`, model, callback, null, { country: model.country });

export const roleDrugList = (params, callback) => get(`/role/drug/list`, callback, params, { country: params.country });
export const roleDrugListGlobal = (params, callback) => get(`/role/drug/list/global`, callback, params, { country: params.country });
export const roleDrugUpdate = (model, callback) => put(`/role/drug`, model, callback, null, { country: model.country });

export const roleDeliveryDrugList = (params, callback) => get(`/role/delivery/drug/list`, callback, params, { country: params.country });
export const roleDeliveryDrugListGlobal = (params, callback) => get(`/role/delivery/drug/list/global`, callback, params, { country: params.country });
export const roleDeliveryDrugUpdate = (model, callback) => put(`/role/delivery/drug`, model, callback, null, { country: model.country });

export const roleParentList = (params, callback) => get(`/role/parent/list`, callback, params, { country: params.country });
export const roleParentListGlobal = (params, callback) => get(`/role/parent/list/global`, callback, params, { country: params.country });
export const roleParentUpdate = (model, callback) => put(`/role/parent`, model, callback, null, { country: model.country });

export const roleLanguageList = (params, callback) => get(`/role/language/list`, callback, params, { country: params.country });
export const roleLanguageListGlobal = (params, callback) => get(`/role/language/list/global`, callback, params, { country: params.country });
export const roleLanguageUpdate = (model, callback) => put(`/role/language`, model, callback, null, { country: model.country });

export const roleNotificationList = (params, callback) => get(`/role/notification/list`, callback, params, { country: params.country });
export const roleNotificationDetail = (params, callback) => get(`/role/notification/detail`, callback, params, { country: params.country });
export const roleNotificationCreate = (model, callback) => post(`/role/notification`, model, callback, null, { country: model.country });
export const roleNotificationUpdate = (model, callback) => put(`/role/notification`, model, callback, null, { country: model.country });
export const roleNotificationMultipleDeleteRestore = (model, callback) => del(`/role/notification`, model, callback, null, { country: model.country });

export const cityList = (params, callback) => get(`/city/list`, callback, params, { country: params.country });
export const cityListGlobal = (params, callback) => get(`/city/list/global`, callback, params, { country: params.country });
export const cityDetail = (params, callback) => get(`/city/detail`, callback, params, { country: params.country });
export const cityCreate = (model, callback) => post(`/city`, model, callback, null, { country: model.country });
export const cityUpdate = (model, callback) => put(`/city`, model, callback, null, { country: model.country });
export const cityMultipleDeleteRestore = (model, callback) => del(`/city`, model, callback, null, { country: model.country });

export const userList = (params, callback) => get(`/user/list`, callback, params, { country: params.country });
export const userDetail = (params, callback) => get(`/user/detail`, callback, params, { country: params.country });
export const userMyDetail = (params, callback) => get(`/user/detail/my`, callback, params, { country: params.country });
export const userCreate = (model, callback) => post(`/user`, model, callback, null, { country: model.country });
export const userUpdate = (model, callback) => put(`/user`, model, callback, null, { country: model.country });
export const userUpdateActivity = (model, callback) => put(`/user/activity`, model, callback, null, { country: model.country });
export const userMultipleDeleteRestore = (model, callback) => del(`/user`, model, callback, null, { country: model.country });
export const userAnonymize = (model, callback) => del(`/user/anonymize`, model, callback, null, { country: model.country });

export const userRelationList = (params, callback) => get(`/user/relation/list`, callback, params);
export const userRelationMyList = (params, callback) => get(`/user/relation/list/my`, callback, params);
export const userRelationUpdate = (model, callback) => post(`/user/relation`, model, callback, null, { country: model.country });

export const userStatusUpdate = (model, callback) => post(`/user/status`, model, callback, null, { country: model.country });

export const userInviteList = (params, callback) => get(`/user/invite/list`, callback, params, { country: params.country });
export const userInviteCreate = (model, callback) => post(`/user/invite`, model, callback, null, { country: model.country });
export const userInviteMultipleDeleteRestore = (model, callback) => del(`/user/invite`, model, callback, null, { country: model.country });

export const localizationList = (params, callback) => get(`/localization/list`, callback, params, { country: params.country });
export const localizationCreate = (model, callback) => post(`/localization`, model, callback, null, { country: model.country });
export const localizationUpdate = (model, callback) => put(`/localization`, model, callback, null, { country: model.country });
export const localizationMultipleDeleteRestore = (model, callback) => del(`/localization`, model, callback, null, { country: model.country });
export const localizationExcelDownload = (country, callback) => download(`/localization/excel`, callback, null, { country });
export const localizationExcelUpload = (country, form, callback) => upload(`/localization/excel`, form, callback, null, { country });

export const templateList = (params, callback) => get(`/template/list`, callback, params);
export const templateDetail = (params, callback) => get(`/template/detail`, callback, params);
export const templateDetailContent = (params, callback) => get(`/template/content`, callback, params);
export const templateCreate = (model, callback) => post(`/template`, model, callback);
export const templateUpdate = (model, callback) => put(`/template`, model, callback);
export const templateDataExport = (params, callback) => download(`/template/data`, callback, params, { country: params.country });
export const templateDataImport = (country, form, callback) => upload(`/template/data`, form, callback, null, { country });

export const noticeList = (params, callback) => get(`/notice/list`, callback, params, { country: params.country });
export const noticeDetail = (params, callback) => get(`/notice/detail`, callback, params, { country: params.country });
export const noticeCreate = (model, callback) => post(`/notice`, model, callback, null, { country: model.country });
export const noticeExternalCreate = (model, callback) => post(`/notice/external`, model, callback, null, { country: model.country });

export const noticeContactList = (params, callback) => get(`/notice/contact/list`, callback, params, { country: params.country });
export const noticeContactDetail = (params, callback) => get(`/notice/contact/detail`, callback, params, { country: params.country });
export const noticeContactCreate = (model, callback) => post(`/notice/contact`, model, callback, null, { country: model.country });
export const noticeContactUpdate = (model, callback) => put(`/notice/contact`, model, callback, null, { country: model.country });
export const noticeContactMultipleDeleteRestore = (model, callback) => del(`/notice/contact`, model, callback, null, { country: model.country });

export const scoreList = (params, callback) => get(`/score/list`, callback, params);
export const scoreMyList = (params, callback) => get(`/score/list/my`, callback, params);
export const scoreDetail = (params, callback) => get(`/score/detail`, callback, params);
export const scoreMyDetail = (params, callback) => get(`/score/detail/my`, callback, params);
export const scoreCreate = (model, callback) => post(`/score`, model, callback, null);
export const scoreUpdate = (model, callback) => put(`/score`, model, callback);

export const conferenceList = (params, callback) => get(`/conference/list`, callback, params);
export const conferenceDetail = (params, callback) => get(`/conference/detail`, callback, params);
export const conferenceCreate = (model, callback) => post(`/conference`, model, callback);
export const conferenceUpdateStatus = (model, callback) => put(`/conference/status`, model, callback);
export const conferenceMultipleDelete = (model, callback) => del(`/conference`, model, callback);
export const conferenceJoin = (model, callback) => post(`/conference/join`, model, callback);
export const conferenceComplete = (model, callback) => put(`/conference/complete`, model, callback);
export const conferenceLeave = (model, callback) => post(`/conference/leave`, model, callback);

export const notificationList = (callback) => get(`/notification/list`, callback);
export const notificationCount = (callback) => get(`/notification/count`, callback);
export const notificationRead = (model, callback) => put(`/notification`, model, callback);
export const notificationReadAll = (model, callback) => put(`/notification/all`, model, callback);
export const notificationDelete = (model, callback) => del(`/notification`, model, callback);

export const deliveryFlowList = (params, callback) => get(`/delivery/flow/list`, callback, params, { country: params.country });
export const deliveryFlowListGlobal = (params, callback) => get(`/delivery/flow/list/global`, callback, params, { country: params.country });
export const deliveryFlowDetail = (params, callback) => get(`/delivery/flow/detail`, callback, params, { country: params.country });
export const deliveryFlowCreate = (model, callback) => post(`/delivery/flow`, model, callback, null, { country: model.country });
export const deliveryFlowUpdate = (model, callback) => put(`/delivery/flow`, model, callback, null, { country: model.country });
export const deliveryFlowMultipleDeleteRestore = (model, callback) => del(`/delivery/flow`, model, callback, null, { country: model.country });
export const deliveryFlowDataExport = (params, callback) => download(`/delivery/flow/data`, callback, params, { country: params.country });
export const deliveryFlowDataImport = (country, form, callback) => upload(`/delivery/flow/data`, form, callback, null, { country });

export const deliveryFlowStepList = (params, callback) => get(`/delivery/flow/step/list`, callback, params, { country: params.country });
export const deliveryFlowStepListGlobal = (params, callback) => get(`/delivery/flow/step/list/global`, callback, params, { country: params.country });
export const deliveryFlowStepDetail = (params, callback) => get(`/delivery/flow/step/detail`, callback, params, { country: params.country });
export const deliveryFlowStepCreate = (model, callback) => post(`/delivery/flow/step`, model, callback, null, { country: model.country });
export const deliveryFlowStepUpdate = (model, callback) => put(`/delivery/flow/step`, model, callback, null, { country: model.country });
export const deliveryFlowStepRowParentUpdate = (model, callback) => put(`/delivery/flow/step/row`, model, callback, null, { country: model.country });
export const deliveryFlowStepDelete = (model, callback) => del(`/delivery/flow/step`, model, callback, null, { country: model.country });

export const deliveryFlowStepNotificationList = (params, callback) => get(`/delivery/flow/step/notification/list`, callback, params, { country: params.country });
export const deliveryFlowStepNotificationDetail = (params, callback) => get(`/delivery/flow/step/notification/detail`, callback, params, { country: params.country });
export const deliveryFlowStepNotificationCreate = (model, callback) => post(`/delivery/flow/step/notification`, model, callback, null, { country: model.country });
export const deliveryFlowStepNotificationUpdate = (model, callback) => put(`/delivery/flow/step/notification`, model, callback, null, { country: model.country });
export const deliveryFlowStepNotificationMultipleDeleteRestore = (model, callback) =>
  del(`/delivery/flow/step/notification`, model, callback, null, { country: model.country });

export const deliveryList = (params, callback) => get(`/delivery/list`, callback, params, { country: params.country });
export const deliveryDetail = (params, callback) => get(`/delivery/detail`, callback, params, { country: params.country });
export const deliveryCreate = (model, callback) => post(`/delivery`, model, callback, null, { country: model.country });
export const deliveryUpdate = (model, callback) => put(`/delivery`, model, callback, null, { country: model.country });
export const deliveryReject = (model, callback) => put(`/delivery/reject`, model, callback, null, { country: model.country });
export const deliveryMultipleDeleteRestore = (model, callback) => del(`/delivery`, model, callback, null, { country: model.country });
export const deliveryUserUpdate = (model, callback) => put(`/delivery/user`, model, callback, null, { country: model.country });
export const deliveryUserIdList = (params, callback) => get(`/delivery/user/id/list`, callback, params, { country: params.country });
export const deliveryReport = (params, callback) => get(`/delivery/report`, callback, params, { country: params.country });

export const deliveryStepList = (params, callback) => get(`/delivery/step/list`, callback, params, { country: params.country });
export const deliveryStepDetail = (params, callback) => get(`/delivery/step/detail`, callback, params, { country: params.country });
export const deliveryStepUpdate = (model, callback) => put(`/delivery/step`, model, callback, null, { country: model.country });

export const contentList = (params, callback) => get(`/content/list`, callback, params, { country: params.userCountry });
export const contentListGlobal = (params, callback) => get(`/content/list/global`, callback, params, { country: params.userCountry });
export const contentDetail = (params, callback) => get(`/content/detail`, callback, params, { country: params.userCountry });
export const contentDetailGlobal = (params, callback) => get(`/content/detail/global`, callback, params, { country: params.userCountry });
export const contentCreate = (model, callback) => post(`/content`, model, callback, null, { country: model.userCountry });
export const contentUpdate = (model, callback) => put(`/content`, model, callback, null, { country: model.userCountry });
export const contentUpdateRow = (model, callback) => put(`/content/row`, model, callback, null, { country: model.userCountry });
export const contentUpdateActivity = (model, callback) => put(`/content/activity`, model, callback, null, { country: model.userCountry });
export const contentMultipleDeleteRestore = (model, callback) => del(`/content`, model, callback, null, { country: model.userCountry });
export const contentNotify = (model, callback) => put(`/content/notify`, model, callback, null, { country: model.userCountry });

export const contentStaticDetail = (params, callback) => get(`/content/static/detail`, callback, params, { country: params.country });
export const contentStaticList = (params, callback) => get(`/content/static/list`, callback, params, { country: params.country });

export const contentUserList = (params, callback) => get(`/content/user/list`, callback, params);
export const contentUserMyList = (params, callback) => get(`/content/user/list/my`, callback, params);
export const contentUserDetail = (params, callback) => get(`/content/user/detail`, callback, params);
export const contentUserSelectionCreate = (model, callback) => post(`/content/user/selection`, model, callback);

export const storageDirectoryList = (callback) => get(`/storage/directory/list`, callback);
export const storageList = (params, callback) => get(`/storage/file/list`, callback, params);
export const storageDelete = (model, callback) => del(`/storage/file`, model, callback);
export const storageDownload = (params, callback) => download(`/storage/download`, callback, params);
export const storageUpload = (form, callback) => upload(`/storage/file`, form, callback);

export const reportList = (callback) => get(`/report/list`, callback);

export const treatmentList = (params, callback) => get(`/treatment/list`, callback, params, { country: params.country });
export const treatmentDetail = (params, callback) => get(`/treatment/detail`, callback, params, { country: params.country });
export const treatmentCreate = (model, callback) => post(`/treatment`, model, callback, null, { country: model.country });
export const treatmentUpdate = (model, callback) => put(`/treatment`, model, callback, null, { country: model.country });
export const treatmentDelete = (model, callback) => del(`/treatment`, model, callback, null, { country: model.country });
export const treatmentSuspend = (model, callback) => put(`/treatment/suspend`, model, callback, null, { country: model.country });

export const treatmentUsageStatusUpdate = (model, callback) => put(`/treatment/usage/status`, model, callback);

export const patientActivityList = (params, callback) => get(`/activity/patient/list`, callback, params);

export const locationRussianAddressList = (params, callback) => get(`/location/ru/address`, callback, params);

export const visitList = (params, callback) => get(`/visit/list`, callback, params, { country: params.country });
export const visitMyList = (params, callback) => get(`/visit/list/my`, callback, params, { country: params.country });
export const visitCreate = (model, callback) => post(`/visit`, model, callback, null, { country: model.country });
export const visitUpdate = (model, callback) => put(`/visit`, model, callback, null, { country: model.country });
export const visitDelete = (model, callback) => del(`/visit`, model, callback, null, { country: model.country });
export const visitSuspend = (model, callback) => put(`/visit/suspend`, model, callback, null, { country: model.country });

export const vitalList = (params, callback) => get(`/vital/list`, callback, params, { country: params.country });
export const vitalMyList = (params, callback) => get(`/vital/list/my`, callback, params, { country: params.country });
export const vitalCreate = (model, callback) => post(`/vital`, model, callback, null, { country: model.country });
export const vitalCreateWithDelivery = (model, callback) => post(`/vital/delivery`, model, callback, null, { country: model.country });
export const vitalUpdate = (model, callback) => put(`/vital`, model, callback, null, { country: model.country });
export const vitalDelete = (model, callback) => del(`/vital`, model, callback, null, { country: model.country });

export const activityPatientList = (params, callback) => get(`/activity/patient/list`, callback, params);
export const activityPatientDetail = (params, callback) => get(`/activity/patient/detail`, callback, params);
