const TreatmentRemindStatusTypes = [
  {
    value: 0,
    name: 'NoRemind',
    displayName: 'TreatmentRemindTypeNoRemind',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Remind',
    displayName: 'TreatmentRemindTypeRemind',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  }
];

export default TreatmentRemindStatusTypes;